import React, { useEffect, useRef, useState } from "react";
import "../../ExecutiveDashboard/ExceutiveDashboard.scss";
import WeatherBoard from "../../../Common/WeatherBoard";
import "../../../Common/FontSizes.scss";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useLocation } from "react-router-dom";

import SimpleBackdrop from "../../../../LifeScience/components/common/SimpleBackdrop";
import { CosmicInverterService } from "../../../Services/CosmicInverterService";
import MinMaxWeather from "../../../Common/MinMaxWeather";
import SingleLineTempGraph from "../../../Graphs/SingleLineTempGraph";
import "./WeatherDashbrd.scss";
import useErrorHandler from "../../../../utils/errorHandler";
import { useSnackbar } from "notistack";
import MultiDateLineGraph from "../../../Graphs/MultiDateLineGraph";
const invService = new CosmicInverterService();
function WeatherDashbrd() {
  const [gridHeight, setGridHeight] = useState(0);

  const [windDateVal, setWindDateVal] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [forecastData, setForecastData] = useState({});
  const [isWeatherData, setIsWeatherData] = useState([]);
  const [panelDeviceId, setPanelDeviceId] = useState(null);

  const [weatherMaxVal, setWeatherMaxVal] = useState({});
  const [isStartTimeWind, setIsStartTimeWind] = useState("");
  const [isStopTimeWind, setIsStopTimeWind] = useState("");
  const [isStartTimeAmbient, setIsStartTimeAmbient] = useState("");
  const [isStopTimeAmbient, setIsStopTimeAmbient] = useState("");
  const [isStartTimeModule, setIsStartTimeModule] = useState("");
  const [isStopTimeModule, setIsStopTimeModule] = useState("");

  const [isStartTimeIrradiance, setIsStartTimeIrradiance] = useState("");
  const [isStopTimeIrradiance, setIsStopTimeIrradiance] = useState("");

  const [isStartTimeAbsolute, setIsStartTimeAbsolute] = useState("");
  const [isStopTimeAbsolute, setIsStopTimeAbsolute] = useState("");

  const [isStartTimeRelative, setIsStartTimeRelative] = useState("");
  const [isStopTimeRelative, setIsStopTimeRelative] = useState("");
  const [deviceId, setDeviceId] = useState(0);
  const [ambientTemp, setAmbientTemp] = useState([]);
  const [moduleTemperature, setModuleTemperature] = useState([]);
  const [relativeHumidity, setRelativeHumidity] = useState([]);

  const [absoluteHumidity, setAbsoluteHumidity] = useState([]);

  const [perfCompareVal, setPerfCompareVal] = useState([]);
  const [isStartTimeDeviceCompare, setIsStartTimeDeviceCompare] = useState("");
  const [isStopTimeDeviceCompare, setIsStopTimeDeviceCompare] = useState("");
  const [deviceFieldValue, setDeviceFieldValue] = useState([]);
  const location = useLocation();
  const wmsfirstField = location.state?.firstField;
  const [isFieldCompareVal, setIsFieldCompareVal] = useState(wmsfirstField ? wmsfirstField : "");

  const [irrandianceOne, setIrrandianceOne] = useState([]);
  const [peakValue, setPeakValue] = useState([]);
  const [weatherInfoData, setWeatherInfoData] = useState({});
  const [loadingState, setLoadingState] = useState({
    weatherParams: false,
    windSpeed: false,
    airTemp: false,
    irraidance: false,
    relhumidity: false,
    abshumidity: false,
    deviceid: false,
    forecast: false,
    weatherData: false,
    moduleTemp: false,
  });

  const gridRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const storedPlantIds = localStorage.getItem("plantIds");
  const plantIdsArray = JSON.parse(storedPlantIds);
  const id = plantIdsArray[0];
  const handleFetchError = useErrorHandler();
  useEffect(() => {
    handlePlantDevices(id);
    handleForecastWeather(id);
    handleWeatherParameters(id);
    handleWeatherInfo(id);
    console.log("wmsfirstField", wmsfirstField);
    
  }, []);
  useEffect(() => {
    handleFieldsList(deviceId);
  }, [deviceId]);
  useEffect(() => {
    if (gridRef.current) {
      const height = gridRef.current.offsetHeight;
      setGridHeight(height);
    }
  }, [gridRef]);
  useEffect(() => {
    if (deviceId) {
      if (isStartTimeWind && isStopTimeWind) {
        handleWindSpeed(deviceId, isStartTimeWind, isStopTimeWind);
      } else {
        const fetchData = () => {
          handleWindSpeed(deviceId);
        };
        fetchData(); // Call fetchData only after the first render
        const intervalId = setInterval(fetchData, 900000);

        return () => {
          clearInterval(intervalId);
        };
      }
    }
  }, [isStopTimeWind]);
  useEffect(() => {
    if (deviceId) {
      if (isStartTimeDeviceCompare && isStopTimeDeviceCompare) {
        handleDeviceCompare(
          deviceId,
          isFieldCompareVal,
          isStartTimeDeviceCompare,
          isStopTimeDeviceCompare
        );
      }
    }
  }, [isFieldCompareVal, isStopTimeDeviceCompare]);

  useEffect(() => {
    const fetchComparisonData = () => {
      if (deviceId) {
        handleDeviceCompare(deviceId, isFieldCompareVal);
      }
    };
    fetchComparisonData();
    const comparisonIntervalId = setInterval(
      fetchComparisonData,
      5 * 60 * 1000
    );
    return () => clearInterval(comparisonIntervalId);
  }, [isFieldCompareVal, deviceId]);

  // useEffect(() => {
  //   const device = panelDeviceId || deviceId;

  //   if (device) {
  //     const fetchData = () => {
  //       if (isStartTimeAmbient && isStopTimeAmbient) {
  //         handleAmbientTemp(device, isStartTimeAmbient, isStopTimeAmbient);
  //       } else {
  //         handleAmbientTemp(device);
  //       }
  //     };

  //     fetchData();
  //     const intervalId = setInterval(fetchData, 900000); // Repeat every 15 minutes

  //     return () => {
  //       clearInterval(intervalId);
  //     };
  //   }
  // }, [panelDeviceId, deviceId, isStartTimeAmbient, isStopTimeAmbient]);

  useEffect(() => {
    if (panelDeviceId) {
      if (isStartTimeModule && isStopTimeModule) {
        handleModuleTemp(panelDeviceId, isStartTimeModule, isStopTimeModule);
      } else {
        const fetchData = () => {
          handleModuleTemp(panelDeviceId);
        };
        fetchData(); // Call fetchData only after the first render
        const intervalId = setInterval(fetchData, 900000);

        return () => {
          clearInterval(intervalId);
        };
      }
    }
  }, [isStopTimeModule]);
  useEffect(() => {
    if (deviceId) {
      if (isStartTimeAbsolute && isStopTimeAbsolute) {
        handleAbsoluteHumidity(
          deviceId,
          isStartTimeAbsolute,
          isStopTimeAbsolute
        );
      } else {
        const fetchData = () => {
          handleAbsoluteHumidity(deviceId);
        };
        fetchData(); // Call fetchData only after the first render
        const intervalId = setInterval(fetchData, 900000);

        return () => {
          clearInterval(intervalId);
        };
      }
    }
  }, [isStopTimeAbsolute]);
  useEffect(() => {
    if (deviceId) {
      if (isStartTimeRelative && isStopTimeRelative) {
        handleRelativeHumidity(
          deviceId,
          isStartTimeRelative,
          isStopTimeRelative
        );
      } else {
        const fetchData = () => {
          handleRelativeHumidity(deviceId);
        };
        fetchData(); // Call fetchData only after the first render
        const intervalId = setInterval(fetchData, 900000);

        return () => {
          clearInterval(intervalId);
        };
      }
    }
  }, [isStopTimeRelative]);
  useEffect(() => {
    if (deviceId) {
      if (isStartTimeIrradiance && isStopTimeIrradiance) {
        handleIrradiance(deviceId, isStartTimeIrradiance, isStopTimeIrradiance);
      } else {
        const fetchData = () => {
          handleIrradiance(deviceId);
        };
        fetchData();
        const intervalId = setInterval(fetchData, 900000);

        return () => {
          clearInterval(intervalId);
        };
      }
    }
  }, [isStopTimeIrradiance]);

  const weatherInfo = [
    {
      title: "Humidity",
      temp: `${weatherInfoData?.humidity ? weatherInfoData?.humidity : "--"}%`,

      wewatherIcon: "https://s3.amazonaws.com/smarttrak.co/v2Images/Vector.svg",
    },
    {
      title: "Wind Speed",
      temp: `${
        weatherInfoData?.windSpeed ? weatherInfoData?.windSpeed : "--"
      }km/h`,
      wewatherIcon: "https://s3.amazonaws.com/smarttrak.co/v2Images/Wind.svg",
    },
    {
      title: "Air Temp",
      temp: `${
        weatherInfoData?.airTemperature ? weatherInfoData?.airTemperature : "--"
      }°C`,
      wewatherIcon:
        "https://s3.amazonaws.com/smarttrak.co/v2Images/Cloud+Wind.svg",
    },
    {
      title: "Irradiance",

      temp: `${
        isWeatherData[0]?.device_data?.irradiance
          ? isWeatherData[0]?.device_data?.irradiance
          : "--"
      } W/m²`,
      wewatherIcon:
        "https://s3.amazonaws.com/smarttrak.co/v2Images/Solarirradiance.svg",
    },
  ];

  const NotificationData = [
    {
      title: "Security alert:",
      disc: "Unauthorized access attempt detected",
    },
    {
      title: "Performance issue detected:",
      disc: "Equipment malfunction reported",
    },
    {
      title: "Weather alert:",
      disc: "Cloudy conditions impacting energy production",
    },
    {
      title: "Scheduled maintenance reminder:",
      disc: "Maintenance required",
    },
    {
      title: "System health alert:",
      disc: "Battery status critical",
    },
    {
      title: "Energy production trend:",
      disc: "Decreasing output observed",
    },
  ];

  const handlePlantDevices = async (id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, deviceid: true }));

      const res = await invService.getPlantDevices(id, "wms");
      if (res.status === 200) {
        setDeviceId(res.data[0].id);
        handleWeatherMaxValues(res.data[0].id);
        handleWindSpeed(res.data[0].id);
        handleRelativeHumidity(res.data[0].id);
        handleAbsoluteHumidity(res.data[0].id);
        handleIrradiance(res.data[0].id);
      }
      setLoadingState((prevState) => ({ ...prevState, deviceid: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, deviceid: false }));
      handleFetchError(error, "plant device data");
    }
  };
  const handleWindSpeed = async (device_id, start_time, end_time) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, windSpeed: true }));

      const res = await invService.getWeatherWindSpeed(
        device_id,
        start_time,
        end_time
      );
      if (res.status === 200) {
        const formattedData = res.data
          // .filter((item) => item.wind_speed !== 0)
          .map((item) => ({
            timestamp_edge: item.timestamp_edge,
            "Wind Speed": item.wind_speed,
          }));
        setWindDateVal(formattedData);
      }
      setLoadingState((prevState) => ({ ...prevState, windSpeed: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, windSpeed: false }));
      handleFetchError(error, "wind data not found");
    }
  };

  // const handleAmbientTemp = async (device_id, start_time, end_time) => {
  //   try {
  //     setLoadingState((prevState) => ({ ...prevState, airTemp: true }));
  //     let res;
  //     try {
  //       res = await invService.getWeatherAmbientTemp(
  //         device_id,
  //         start_time,
  //         end_time
  //       );
  //     } catch (error) {
  //       if (error.response && error.response.status !== 404) {
  //         throw error;
  //       }
  //       res = await invService.getAmbientTemp(device_id, start_time, end_time);
  //     }
  //     if (res.status === 200) {
  //       const formattedData = res.data.map((item) => ({
  //         timestamp_edge: item.timestamp_edge,
  //         "Ambient Temperature": item.ambient_temperature_degree_C,
  //       }));
  //       setAmbientTemp(formattedData);
  //     } else {
  //       throw new Error("Failed to fetch ambient temperature data");
  //     }
      
  //     setLoadingState((prevState) => ({ ...prevState, airTemp: false }));
  //   } catch (error) {
  //     setLoadingState((prevState) => ({ ...prevState, airTemp: false }));
  //     handleFetchError(error, "Air temperature data not found");
  //   }
  // };

 const handleModuleTemp = async (device_id, start_time, end_time) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, moduleTemp: true }));

      const res = await invService.getModuleTemp(
        device_id,
        start_time,
        end_time
      );
      if (res.status === 200) {
        const formattedData = res.data
          // .filter((item) => item.module_temperature_degree_C !== 0)
          .map((item) => ({
            timestamp_edge: item.timestamp_edge,
            "Module Temperature": item.module_temperature_degree_C,
          }));
        setModuleTemperature(formattedData);
      }
      setLoadingState((prevState) => ({ ...prevState, moduleTemp: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, moduleTemp: false }));
      handleFetchError(error, "module temperature data not found");
    }
  };
  const handleAbsoluteHumidity = async (device_id, start_time, end_time) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, abshumidity: true }));

      const res = await invService.getWeatherAbsoluteHumidity(
        device_id,
        start_time,
        end_time
      );
      if (res.status === 200) {
        const formattedData = res.data
          // .filter((item) => item.absolute_humidity_act !== 0)
          .map((item) => ({
            timestamp_edge: item.timestamp_edge,
            "Absolute Humidity": item.absolute_humidity_act,
          }));
        setAbsoluteHumidity(formattedData);
      }
      setLoadingState((prevState) => ({ ...prevState, abshumidity: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, abshumidity: false }));
      handleFetchError(error, "absolute humidity data not found");
    }
  };

  const handleFieldsList = async (device_id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, fieldList: true }));
      const res = await invService.getListDeviceFieldsWMS("wms", device_id);
      if (res.status === 200) {
        setDeviceFieldValue(res.data);

        setLoadingState((prevState) => ({ ...prevState, fieldList: false }));
      }
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, fieldList: false }));
      handleFetchError(error, "device fields");
    }
  };

  const handleDeviceCompare = async (id, fields, start_time, end_time) => {
    try {
      if (fields.length === 0) {
        setPerfCompareVal([]);
        // enqueueSnackbar("Please Select Fields to Compare", {
        //   variant: "warning",
        // });
      } else {
        setLoadingState((prevState) => ({ ...prevState, deviceCompare: true }));
        const res = await invService.getDeviceCompareWMS(
          "wms",
          id,
          fields,
          start_time,
          end_time
        );
        if (res.status === 200) {
          const formattedData = res.data.map((item) => {
            const formattedItem = { timestamp_edge: item.timestamp_edge };
            Object.keys(item).forEach((field) => {
              if (field !== "timestamp_edge" && field !== "device_name") {
                const formattedField = field
                  .replace("_", " ")
                  .replace(/\b\w/g, (c) => c.toUpperCase());
                let fieldValue = item[field];
                if (typeof fieldValue === "number") {
                  fieldValue = fieldValue.toFixed(2);
                }
                formattedItem[formattedField] = fieldValue;
              }
            });
            return formattedItem;
          });
          setPerfCompareVal(formattedData);
          setLoadingState((prevState) => ({
            ...prevState,
            deviceCompare: false,
          }));
        }
      }
    } catch (error) {
      setPerfCompareVal([]);

      setLoadingState((prevState) => ({ ...prevState, deviceCompare: false }));
      handleFetchError(error, "Device Fields Comparison");
    }
  };

  const handleRelativeHumidity = async (device_id, start_time, end_time) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, relhumidity: true }));

      const res = await invService.getWeatherRelativeHumidity(
        device_id,
        start_time,
        end_time
      );
      if (res.status === 200) {
        const formattedData = res.data
          // .filter((item) => item.relative_humidity_act !== 0)
          .map((item) => ({
            timestamp_edge: item.timestamp_edge,
            "Relative Humidity": item.relative_humidity_act,
          }));
        setRelativeHumidity(formattedData);
      }
      setLoadingState((prevState) => ({ ...prevState, relhumidity: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, relhumidity: false }));
      handleFetchError(error, "relative humidity data not found");
    }
  };
  const handleIrradiance = async (device_id, start_time, end_time) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, irraidance: true }));

      const res = await invService.getWeatherIrradiance(
        device_id,
        start_time,
        end_time
      );
      if (res.status === 200) {
        const peakValueArray = [];
        const formattedData = res.data
          // .filter((item) => item.irradiance !== 0)
          .map((item) => ({
            timestamp_edge: item.timestamp_edge,
            Irradiance: item.irradiance,
          }));

        setIrrandianceOne(formattedData);
        const totalIrradiance = formattedData.reduce(
          (sum, data) => sum + data.Irradiance,
          0
        );
        const peakValue = {
          peak_active_power: totalIrradiance / formattedData.length,
          peak_name: "Average Irradiance :",
          peak_unit: " W/m²",
        };
        peakValueArray.push(peakValue);
        setPeakValue(peakValueArray);
      }
      setLoadingState((prevState) => ({ ...prevState, irraidance: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, irraidance: false }));
      handleFetchError(error, "Irradiance data not found");
    }
  };
  const handleForecastWeather = async (id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, forecast: true }));

      const res = await invService.getForecastWeather(id);
      if (res.status === 200) {
        const filteredWeatherData = {};
        res.data.list.forEach((item) => {
          const date = new Date(item.dt * 1000);
          const dateKey = `${date.getFullYear()}-${(date.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;

          if (!filteredWeatherData[dateKey]) {
            filteredWeatherData[dateKey] = item;
          }
        });

        const finalWeatherData = Object.values(filteredWeatherData);
        const filteredFinalWeatherData = finalWeatherData.slice(1, -1);
        const combinedData = {
          ...res.data,
          list: filteredFinalWeatherData,
        };
        setForecastData(combinedData);
      }
      setLoadingState((prevState) => ({ ...prevState, forecast: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, forecast: false }));
      handleFetchError(error, "forecast data not found");
    }
  };

  const handleWeatherParameters = async (id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, weatherData: true }));

      const resWMS = await invService.getDeviceLevelData(id, "wms");
      if (resWMS.status === 200) {
        setIsWeatherData(resWMS.data);
      }
      const resPlant = await invService.getDeviceLevelData(id, "panel");
      if (resPlant.status === 200) {
        setPanelDeviceId(resPlant.data[0].device_data.device_id);
        handleModuleTemp(resPlant.data[0].device_data.device_id);
        // handleAmbientTemp(resPlant.data[0].device_data.device_id);
      }
      setLoadingState((prevState) => ({ ...prevState, weatherData: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, weatherData: false }));
      // handleFetchError(error, "weather data not found");
    }
  };
  const handleWeatherInfo = async (id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, weatherData: true }));

      const res = await invService.getWeatherInfo(id);
      if (res.status === 200) {
        const timestamp = res.data.dt * 1000;
        const date = new Date(timestamp);

        const month = date.toLocaleString("default", { month: "long" });
        const dayAbbreviation = date.toLocaleString("en-US", {
          weekday: "long",
        });
        setWeatherInfoData({
          weatherConditions: res.data.weather[0].main,
          weatherUrl: res.data.weather[0].icon,

          temperature: res.data.main.feels_like,
          airTemperature: res.data.main.temp,

          minTemp: res.data.main.temp_min,
          maxTemp: res.data.main.temp_max,
          humidity: res.data.main.humidity,
          windSpeed: res.data.wind.speed,
          day: dayAbbreviation,
          date: date.getDate(),
          month: month,
        });
      }
      setLoadingState((prevState) => ({ ...prevState, weatherData: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, weatherData: false }));
      handleFetchError(error, "weatherdata data not found");
    }
  };
  const handleWeatherMaxValues = async (device_id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, weatherParams: true }));

      const res = await invService.getWeatherMaxValues(device_id);
      if (res.status === 200) {
        setWeatherMaxVal(res.data);
      }
      setLoadingState((prevState) => ({ ...prevState, weatherParams: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, weatherParams: false }));
      handleFetchError(error, "weatherdata data not found");
    }
  };

  return (
    <div className="home-container" style={{ position: "relative" }}>
      {loadingState.deviceid && <SimpleBackdrop open={loadingState.deviceid} />}
      <Grid container className="weatherDash-container">
        <Grid
          item
          lg={9.6}
          xs={12}
          sm={12}
          md={9}
          className="scrolling-grid-exc"
        >
          <MinMaxWeather
            weatherData={weatherMaxVal}
            loader={loadingState.weatherParams}
          />

          <Grid
            item
            lg={12}
            xs={12}
            md={11.9}
            sm={12}
            className="exc-graph-grid"
          >
            <Paper ref={gridRef} className="exc-graph-paper">
              <SingleLineTempGraph
                isValueData={windDateVal}
                titleText="Wind Speed"
                openDrawerValue={setDrawerOpen}
                StartTimer={setIsStartTimeWind}
                StopTimer={setIsStopTimeWind}
                yAxisText="Wind Speed (km/h)"
                legendName="Wind Speed"
                indicateUnit="km/h"
                calenderClick="on"
                selectedDateRangeProp={[
                  {
                    startDate: isStartTimeWind ? isStartTimeWind : new Date(),
                    endDate: isStopTimeWind ? isStopTimeWind : new Date(),
                    key: "selection",
                  },
                ]}
              />
            </Paper>
            {loadingState.windSpeed && (
              <SimpleBackdrop open={loadingState.windSpeed} />
            )}
          </Grid>

          {/* <Grid
            item
            lg={12}
            xs={12}
            md={11.9}
            sm={12}
            className="exc-graph-grid"
          >
            <Paper className="exc-graph-paper">
              <SingleLineTempGraph
                isValueData={ambientTemp}
                titleText="Ambient Temperature"
                openDrawerValue={setDrawerOpen}
                StartTimer={setIsStartTimeAmbient}
                StopTimer={setIsStopTimeAmbient}
                yAxisText="Ambient Temperature (°C)"
                legendName="Ambient Temperature "
                indicateUnit="(°C)"
                calenderClick="on"
                selectedDateRangeProp={[
                  {
                    startDate: isStartTimeAmbient
                      ? isStartTimeAmbient
                      : new Date(),
                    endDate: isStopTimeAmbient ? isStopTimeAmbient : new Date(),
                    key: "selection",
                  },
                ]}
              />
            </Paper>
            {loadingState.airTemp && (
              <SimpleBackdrop open={loadingState.airTemp} />
            )}
          </Grid> */}

          <Grid
            item
            lg={12}
            xs={12}
            md={12}
            sm={11.9}
            className="exc-graph-grid"
          >
            <Paper className="exc-graph-paper">
              <SingleLineTempGraph
                isValueData={irrandianceOne}
                titleText="Irradiance"
                openDrawerValue={setDrawerOpen}
                StartTimer={setIsStartTimeIrradiance}
                StopTimer={setIsStopTimeIrradiance}
                yAxisText="Irradiance(W/m²)"
                legendName="Irradiance"
                indicateUnit="W/m²"
                calenderClick="on"
                ispeakValue={peakValue}
                selectedDateRangeProp={[
                  {
                    startDate: isStartTimeIrradiance
                      ? isStartTimeIrradiance
                      : new Date(),
                    endDate: isStopTimeIrradiance
                      ? isStopTimeIrradiance
                      : new Date(),
                    key: "selection",
                  },
                ]}
              />
            </Paper>
            {loadingState.irraidance && (
              <SimpleBackdrop open={loadingState.irraidance} />
            )}
          </Grid>
          <Grid
            item
            lg={12}
            xs={12}
            md={11.9}
            sm={12}
            className="exc-graph-grid"
          >
            <Paper className="exc-graph-paper">
              <SingleLineTempGraph
                isValueData={relativeHumidity}
                titleText="Relative Humidity"
                openDrawerValue={setDrawerOpen}
                StartTimer={setIsStartTimeRelative}
                StopTimer={setIsStopTimeRelative}
                yAxisText="Relative Humidity"
                legendName="Relative Humidity"
                indicateUnit="%"
                calenderClick="on"
                selectedDateRangeProp={[
                  {
                    startDate: isStartTimeRelative
                      ? isStartTimeRelative
                      : new Date(),
                    endDate: isStopTimeRelative
                      ? isStopTimeRelative
                      : new Date(),
                    key: "selection",
                  },
                ]}
              />
            </Paper>
            {loadingState.relhumidity && (
              <SimpleBackdrop open={loadingState.relhumidity} />
            )}
          </Grid>
          <Grid
            item
            lg={12}
            xs={12}
            md={11.9}
            sm={12}
            className="exc-graph-grid"
          >
            <Paper className="exc-graph-paper">
              <SingleLineTempGraph
                isValueData={absoluteHumidity}
                titleText="Absolute Humidity"
                openDrawerValue={setDrawerOpen}
                StartTimer={setIsStartTimeAbsolute}
                StopTimer={setIsStopTimeAbsolute}
                yAxisText="Absolute Humidity"
                legendName="Absolute Humidity"
                indicateUnit="g/m3"
                calenderClick="on"
                selectedDateRangeProp={[
                  {
                    startDate: isStartTimeAbsolute
                      ? isStartTimeAbsolute
                      : new Date(),
                    endDate: isStopTimeAbsolute
                      ? isStopTimeAbsolute
                      : new Date(),
                    key: "selection",
                  },
                ]}
              />
            </Paper>
            {loadingState.abshumidity && (
              <SimpleBackdrop open={loadingState.abshumidity} />
            )}
          </Grid>
          {moduleTemperature.length > 0 && (
            <Grid
              item
              lg={12}
              xs={12}
              md={11.9}
              sm={12}
              className="exc-graph-grid"
            >
              <Paper className="exc-graph-paper">
                <SingleLineTempGraph
                  isValueData={moduleTemperature}
                  titleText="Module Temperature"
                  openDrawerValue={setDrawerOpen}
                  StartTimer={setIsStartTimeModule}
                  StopTimer={setIsStopTimeModule}
                  yAxisText="Module Temperature (°C)"
                  legendName="Module Temperature "
                  indicateUnit="(°C)"
                  calenderClick="on"
                  selectedDateRangeProp={[
                    {
                      startDate: isStartTimeModule
                        ? isStartTimeModule
                        : new Date(),
                      endDate: isStopTimeModule ? isStopTimeModule : new Date(),
                      key: "selection",
                    },
                  ]}
                />
              </Paper>
              {loadingState.moduleTemp && (
                <SimpleBackdrop open={loadingState.moduleTemp} />
              )}
            </Grid>
          )}
          <Grid item lg={11.9} xs={12} md={11.9} className="exc-graph-grid">
            <Paper className="exc-graph-paper">
              <MultiDateLineGraph
                isValueData={perfCompareVal}
                StartTimer={setIsStartTimeDeviceCompare}
                StopTimer={setIsStopTimeDeviceCompare}
                openDrawerValue={setDrawerOpen}
                titleText="Weather Fields Comparison"
                legendName=""
                calenderClick="on"
                selectedType={setIsFieldCompareVal}
                fieldsDropdata={deviceFieldValue}
                defaultValue={isFieldCompareVal}
                selectedDateRangeProp={[
                  {
                    startDate: isStartTimeDeviceCompare
                      ? isStartTimeDeviceCompare
                      : new Date(),
                    endDate: isStopTimeDeviceCompare
                      ? isStopTimeDeviceCompare
                      : new Date(),
                    key: "selection",
                  },
                ]}
              />
              {loadingState.deviceCompare && (
                <SimpleBackdrop open={loadingState.deviceCompare} />
              )}
              {loadingState.fieldList && (
                <SimpleBackdrop open={loadingState.fieldList} />
              )}
            </Paper>
          </Grid>
        </Grid>
        <Grid
          item
          lg={2.3}
          xs={12}
          sm={12}
          md={2.8}
          className="weather_grid_weather"
        >
          <Paper className="notification-paper-one">
            <WeatherBoard
              weatherData={weatherInfo}
              weatherParam={weatherInfoData}
              weaHeight="90vh"
            />
          </Paper>
          {loadingState.weatherData && (
            <SimpleBackdrop open={loadingState.weatherData} />
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default WeatherDashbrd;
