import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import NoPage from "./LifeScience/screens/Common/NoPage";
import ForgotPass from "./Auth/ForgotPass";
import SignUp from "./Auth/Signup";
import Signin from "./Auth/Signin";
import Verify from "./Auth/Verify";
import HomePage from "./V2DEV/DashBoard/HomePage"
import Dashboard from "./LifeScience/screens/Dashboard/Dashboard";
import MainHomePage from "./LifeScience/screens/Dashboard/MainHomePage";
import VerifyPassword from "./Auth/VerifyPassword";
import ThreedPdb from "./LifeScience/screens/PDB/ThreedPdb";
import Subscription from "./IOT/Screens/SubscriptionPage/Subscription";
import Home from "./LandingPage/MainLandingPage/Home";
import Casestudy from "./LandingPage/CaseStudy/Casestudy";
import Footer from "./LandingPage/MainLandingPage/Footer";
import MainCaseStudy from "./LandingPage/MainCaseStudyPage.js/MainCaseStudy";
import ProductSolns from "./LandingPage/ProductSolns/ProductSolns";
import ProductDetails from "./LandingPage/ProductSolns/ProductDetails";
import MainServices from "./LandingPage/Services/MainServices";
import { ApiHelper } from "./IOT/Services/helper/ApiHelper";
import AboutUs from "./LandingPage/AboutUs/AboutUs";
import SmartIQEdge from "./LandingPage/ProductSolns/SmartIQEdge";
import EachService from "./LandingPage/Services/EachService";
import AcceptInvite from "./Auth/AcceptInvite";
import Global from "../src/IOT/Screens/SolarTracker/Globe";
// import ThreeDModel from "./IOT/Screens/threeDModel/ThreeDModel";
import PowerOptix from "./LandingPage/PowerOptix/PowerOptix";
import EnergyProductsPage from "./LandingPage/PowerOptix/EnergyProductsPage";
import ThreeDProductsPage from "./LandingPage/PowerOptix/ThreeDProductsPage";
import DroneProductsPage from "./LandingPage/PowerOptix/DroneProductsPage";
import FaultProductsPage from "./LandingPage/PowerOptix/FaultProductsPage";
import RemoteProductsPage from "./LandingPage/PowerOptix/RemoteProductsPage";
import AiControllerProductsPage from "./LandingPage/PowerOptix/AiControllerProductsPage";

export default function App() {
  const PrivateRoute = ({ component: Component, ...rest }) => {
    const authToken = ApiHelper.getToken();
    return (
      <Route
        {...rest}
        render={(props) =>
          authToken ? (
            <Component {...props} />
          ) : (
            <Redirect to="/signin" />
          )
        }
      />
    );
  };
  return (
    <BrowserRouter>
      <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/case-studies" component={MainCaseStudy} />
          <Route exact path="/products-solutions" component={ProductSolns} />
          {/* <Route exact path="/product-details" component={ProductDetails} /> */}
          <Route exact path="/products/poweroptix" component={PowerOptix} />
          <Route exact path="/products/three_d_twin" component={ThreeDProductsPage} />
          <Route exact path="/products/energy_forecasting" component={EnergyProductsPage} />
          <Route exact path="/products/drone_integration" component={DroneProductsPage} />
          <Route exact path="/products/fault_prediction" component={FaultProductsPage} />
          <Route exact path="/products/remote_management" component={RemoteProductsPage} />
          <Route exact path="/products/tracker_controller" component={AiControllerProductsPage} />
          <Route exact path="/case-study/:index" component={Casestudy} />
          <Route exact path="/about-us" component={AboutUs} />
          <Route exact path="/products/smartiq_edge" component={SmartIQEdge} />
          <Route exact path="/our-services" component={MainServices} />
          <Route exact path="/our-services/:index" component={EachService} />

         
          {/* <Route exact path="/three-d-model" component={ThreeDModel} /> */}

          <Route exact path="/globe" component={Global} />
          <Route exact path="/signin" component={Signin} />
          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/forgot-password" component={ForgotPass} />
          <Route exact path="/verify" component={Verify} />
          <Route exact path="/accept_invite" component={AcceptInvite} />
          <PrivateRoute exact path="/subscribe" component={Subscription} />
          <PrivateRoute path="/home" component={Dashboard} />
          <Route path ="/dashboard" component={MainHomePage}/>
          <PrivateRoute path ="/threed" component={ThreedPdb}/>
          <PrivateRoute path ="/iot-home" component={HomePage}/>
          <Route exact path="/verify-pass" component={VerifyPassword} />
          <Route exact path="/footer" component={Footer} />

          <Route path="*" component={NoPage} />
      </Switch>
    </BrowserRouter>
  );
}