import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Paper, Grid, Box } from '@mui/material';
import "./ReportFailure.scss";

const ReportRow = ({ report }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Box sx={{ mb: 2 }}>

      <Grid container className='failure-data-Container'  sx={{borderRadius: isExpanded ? "0.625rem 0.625rem 0rem 0rem" : "0.625rem"}}>
        <Grid item xs={0.5}><img src={report.raisedBy.avatar} alt="avatar"  className='report_img' /></Grid>
        <Grid item xs={2}><p className='report_400'>{report.raisedBy.name}</p><p className='Poppins_12px_rem' style={{ color: "#969696" }}>{report.raisedBy.email}</p></Grid>
        <Grid item xs={2}><p className='report_400' >{report.id}</p></Grid>
        <Grid item xs={2}><p className='report_400'>{report.resolvedBy}</p></Grid>
        <Grid item xs={2}><p className='report_400' >{report.reportDate}</p></Grid>
        <Grid item xs={2}><p className='report_400' style={{  color: "#FF472E" }}>{report.faultType}</p></Grid>
        <Grid item xs={1.5}>
          <div onClick={handleToggleExpand} className='open-close-btn'>
            {isExpanded ? <><p className='report_400' style={{  color: "#2F67F8" }}>Close Details</p><ExpandLessIcon sx={{ color: "#2F67F8", cursor: "pointer" }} /></> : <><p className='report_400' style={{color: "#2F67F8" }}>View Details</p><ExpandMoreIcon sx={{ color: "#2F67F8", cursor: "pointer" }} /></>}
          </div>
        </Grid>
      </Grid>
      {isExpanded && (
        <Paper className='expanded_paper'>
          <Grid container className='expanded_container'>
            <Grid item xs={3.8} className='notes-container' >
              <div className='notes_bg'><p className='Poppins_16px_rem'>Notes</p></div>
              <p className='report_400 imp_notification_block' >{report.notes}</p>
            </Grid>
            <Grid item xs={3.8} className='notes-container' sx={{ backgroundColor: "#E6EEF5" }}>
              <div className='notes_bg'><p className='Poppins_16px_rem'>Cause of Failure</p></div>
              <p className='report_400 imp_notification_block' >{report.causeOfFailure}</p>
            </Grid>
            <Grid item xs={3.8} className='notes-container' sx={{ backgroundColor: "#F4F4F4" }}>
              <div className='notes_bg'><p className='Poppins_16px_rem'>Recommendations</p></div>
              <p className='report_400 imp_notification_block' >{report.recommendations}</p>
            </Grid>
          </Grid>
        </Paper>
      )}
    </Box>
  );
};

const ReportList = ({ reports }) => {
  return (
    <div className='imp_notification_block'>
      <Grid container className='failure-Container'>
        <Grid item xs={0.5}></Grid>
        <Grid item xs={2}><p className='Poppins_16px_rem'>Raised By</p></Grid>
        <Grid item xs={2}><p className='Poppins_16px_rem'>Report ID</p></Grid>
        <Grid item xs={2}><p className='Poppins_16px_rem'>Resolved By</p></Grid>
        <Grid item xs={2}><p className='Poppins_16px_rem'>Report Date</p></Grid>
        <Grid item xs={2}><p className='Poppins_16px_rem'>Fault Type</p></Grid>
        <Grid item xs={1.5}><p className='Poppins_16px_rem'>Actions</p></Grid>
      </Grid>
      {reports.map(report => (
        <ReportRow key={report.id} report={report} />
      ))}
    </div>
  );
};

const ReportFailure = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const reports = [
    {
      id: '201746565',
      raisedBy: { name: 'Admin', email: 'admin@smarttrak.co', avatar: 'https://i.pravatar.cc/40' },
      resolvedBy: 'Namrata Nakhale',
      reportDate: '18-03-2024',
      faultType: 'Electrical Overload',
      notes: 'The inverter has detected an electrical overload and has shut down.',
      causeOfFailure: 'Despite advanced protections, electrical overload can still occur if there\'s a sudden surge in power from the solar array, possibly due to faults in individual panels or a failure in the MPPT process.',
      recommendations: 'Isolate and inspect the solar panel array to identify faulty panels or wiring. Ensure that the inverter\'s MPPT algorithms are tuned to respond rapidly to changing conditions.',
    },
    {
      id: '201746566',
      raisedBy: { name: 'Admin', email: 'admin@smarttrak.co', avatar: 'https://i.pravatar.cc/40' },
      resolvedBy: 'Namrata Nakhale',
      reportDate: '18-03-2024',
      faultType: 'Electrical Overload',
      notes: 'The inverter has detected an electrical overload and has shut down.',
      causeOfFailure: 'Despite advanced protections, electrical overload can still occur if there\'s a sudden surge in power from the solar array, possibly due to faults in individual panels or a failure in the MPPT process.',
      recommendations: 'Isolate and inspect the solar panel array to identify faulty panels or wiring. Ensure that the inverter\'s MPPT algorithms are tuned to respond rapidly to changing conditions.',
    },
    {
      id: '201746567',
      raisedBy: { name: 'Admin', email: 'admin@smarttrak.co', avatar: 'https://i.pravatar.cc/40' },
      resolvedBy: 'Namrata Nakhale',
      reportDate: '18-03-2024',
      faultType: 'Electrical Overload',
      notes: 'The inverter has detected an electrical overload and has shut down.',
      causeOfFailure: 'Despite advanced protections, electrical overload can still occur if there\'s a sudden surge in power from the solar array, possibly due to faults in individual panels or a failure in the MPPT process.',
      recommendations: 'Isolate and inspect the solar panel array to identify faulty panels or wiring. Ensure that the inverter\'s MPPT algorithms are tuned to respond rapidly to changing conditions.',
    },
    {
      id: '201746568',
      raisedBy: { name: 'Admin', email: 'admin@smarttrak.co', avatar: 'https://i.pravatar.cc/40' },
      resolvedBy: 'Namrata Nakhale',
      reportDate: '18-03-2024',
      faultType: 'Electrical Overload',
      notes: 'The inverter has detected an electrical overload and has shut down.',
      causeOfFailure: 'Despite advanced protections, electrical overload can still occur if there\'s a sudden surge in power from the solar array, possibly due to faults in individual panels or a failure in the MPPT process.',
      recommendations: 'Isolate and inspect the solar panel array to identify faulty panels or wiring. Ensure that the inverter\'s MPPT algorithms are tuned to respond rapidly to changing conditions.',
    },
    {
      id: '201746569',
      raisedBy: { name: 'Admin', email: 'admin@smarttrak.co', avatar: 'https://i.pravatar.cc/40' },
      resolvedBy: 'Namrata Nakhale',
      reportDate: '18-03-2024',
      faultType: 'Electrical Overload',
      notes: 'The inverter has detected an electrical overload and has shut down.',
      causeOfFailure: 'Despite advanced protections, electrical overload can still occur if there\'s a sudden surge in power from the solar array, possibly due to faults in individual panels or a failure in the MPPT process.',
      recommendations: 'Isolate and inspect the solar panel array to identify faulty panels or wiring. Ensure that the inverter\'s MPPT algorithms are tuned to respond rapidly to changing conditions.',
    },
    {
      id: '201746570',
      raisedBy: { name: 'Admin', email: 'admin@smarttrak.co', avatar: 'https://i.pravatar.cc/40' },
      resolvedBy: 'Namrata Nakhale',
      reportDate: '18-03-2024',
      faultType: 'Electrical Overload',
      notes: 'The inverter has detected an electrical overload and has shut down.',
      causeOfFailure: 'Despite advanced protections, electrical overload can still occur if there\'s a sudden surge in power from the solar array, possibly due to faults in individual panels or a failure in the MPPT process.',
      recommendations: 'Isolate and inspect the solar panel array to identify faulty panels or wiring. Ensure that the inverter\'s MPPT algorithms are tuned to respond rapidly to changing conditions.',
    },
    // Add more reports as needed
  ];
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredReports = reports.filter(report =>
    report.id.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <div className='home-container'>
      
      <Grid
        container className='grid-container'
        
      >
        <Grid
          item
          lg={11.9}
          xs={12}
          sm={12}
          md={11.9} className='paper_borderless'
         
        >
          <Paper className='powerplant-sec-paper'
           sx={{justifyContent:"space-between"}}
          >
            <div className='failure_title'>
              <p className='Poppins_24px_black'>Report Failures</p>
              <p className='report_400' >Addressing Solar System Challenges</p>
            </div>
            <div className="search-box_smb">
                    <input className="search_Text" id="search-input_report"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      type="text"
                      placeholder="Search by Report ID"
                    />
              </div>
          </Paper>
        </Grid>
      </Grid>
      <ReportList reports={filteredReports} />
    </div>
  );
};

export default ReportFailure;
