import React, { useEffect, useRef, useState } from "react";
import "../../ExecutiveDashboard/ExceutiveDashboard.scss";
import "../../../Common/FontSizes.scss";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import EnergyData from "../../../Common/EnergyData";
import SimpleBackdrop from "../../../../LifeScience/components/common/SimpleBackdrop";
import { CosmicInverterService } from "../../../Services/CosmicInverterService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useHistory } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import CircleIcon from "@mui/icons-material/Circle";
import CustomCircularBar from "../../../Common/CustomCircularBar";
import useErrorHandler from "../../../../utils/errorHandler";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const invService = new CosmicInverterService();
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 13,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "green" : "#308fe8",
  },
}));
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "#F4F4F4 !important",
    border: "1px solid #2F67F8 !important",
    color: "black", // Change text color if needed
  },
  [`& .MuiTooltip-arrow`]: {
    color: "#2F67F8 !important",
  },
});
function InverterDashboard() {
  const [gridHeight, setGridHeight] = useState(0);
  const [loadingState, setLoadingState] = useState({
    invList: false,
    fieldList: false,
    invDashbrd: false,
    ghiOverview: false,
  });
  const [gridHeightTwo, setGridHeightTwo] = useState(0);

  const [invDashboard, setInvDashboard] = useState({});
  const [ghiOverview, setGHIOverview] = useState({});
  const [newInvArray, setNewInvArray] = useState([]);
  const [selectedInverters, setSelectedInverters] = useState([]);
  const [deviceFieldValue, setDeviceFieldValue] = useState([]);
  const [totalPowerGen, setTotalPowerGen] = useState(0);
  const [totalCapacity, setTotalCapacity] = useState(0);
  const [currentPage, setCurrentPage] = useState(0); 
  const itemsPerPage = 4; 
  const totalPages = Math.ceil(newInvArray.length / itemsPerPage);
  const history = useHistory();
  const gridRef = useRef(null);
  const gridRefTwo = useRef(null);
  const storedPlantIds = localStorage.getItem("plantIds");
  const plantIdsArray = JSON.parse(storedPlantIds);
  const id = plantIdsArray[0];
  const handleFetchError = useErrorHandler();
  const inverterNames = newInvArray
    .filter(
      (item) =>
        item.device_details !== null && item.device_details.name !== null
    )
    .map((item) => item.device_details.name);

  const columns = [
    { field: "inverter", title: "Inverter" },
    { field: "fault", title: "Fault Prediction %" },
    { field: "capacity", title: "Capacity" },
    { field: "avg_power", title: "Power" },
    { field: "status", title: "Status" },
    { field: "last_time", title: "Last Updated Timestamp" },
  ];

  useEffect(() => {
    handleFieldsList();
    handleInvertersList(id);
    handleInverterDashboard(id);
    handleGHIOverview(id);
    const fetchDataPeriodically = () => {
      const intervalId = setInterval(async () => {
        if (id) {
          await Promise.all([handleInvertersList(id)]);
        }
      }, 10 * 60 * 1000); // 10 minutes interval
      return () => clearInterval(intervalId);
    };

    fetchDataPeriodically();
  }, []);
  useEffect(() => {
    if (gridRef.current) {
      const height = gridRef.current.offsetHeight;
      setGridHeight(height);
    }
  }, [gridRef]);
  useEffect(() => {
    if (gridRefTwo.current) {
      const heightTwo = gridRefTwo.current.offsetHeight;
      setGridHeightTwo(heightTwo);
    }
  }, [gridRefTwo]);

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };
  const startIndex = currentPage * itemsPerPage;
  let visibleInverters = newInvArray.slice(startIndex, startIndex + itemsPerPage);
   if (currentPage === 0) {
    visibleInverters = [
      {
        device_details:{name: "Inverters Dashboard"},
      },
      ...visibleInverters,
    ];
  }
  
  const handleInvertersList = async (id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, invList: true }));
      const res = await invService.getDeviceLevelData(id, "inverter");
      if (res.status === 200) {
        console.log("response", res.data);
        
        setNewInvArray(res.data);
        const totalOutputPower = res.data.reduce((total, device) => {
          if (
            device.device_data &&
            device.device_data.output_power_kW !== null
          ) {
            return total + device.device_data.output_power_kW;
          } else {
            return total;
          }
        }, 0);
        const sumCapacity = res.data.reduce((total, inverter) => {
          const capacityKW =
            inverter.device_details?.device_specific_details?.capacity_kW || 0;
          return total + capacityKW;
        }, 0);
        setTotalCapacity(sumCapacity);
        setTotalPowerGen(totalOutputPower);
      }
      setLoadingState((prevState) => ({ ...prevState, invList: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, invList: false }));
      handleFetchError(error, "Inverter List");
    }
  };

  const handleFieldsList = async () => {
    try {
      setLoadingState((prevState) => ({ ...prevState, fieldList: true }));
      const res = await invService.getListDeviceFields("inverter");
      if (res.status === 200) {
        setDeviceFieldValue(res.data);
        setLoadingState((prevState) => ({ ...prevState, fieldList: false }));
      }
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, fieldList: false }));
      handleFetchError(error, "device fields");
    }
  };

  const handleInverterClick = (invData, index) => {
    if (index !== 0) {
      history.push({
        pathname: `/iot-home/dev-monitoring/inv-dashboard/inv-details`,
        state: {
          invDeviceData: invData,
          ghiData: ghiOverview,
        },
      });
    }
  };

  const handleCheckboxClick = (index) => {
    setSelectedInverters((prevSelected) => {
      if (prevSelected.includes(index)) {
        return prevSelected.filter((i) => i !== index);
      } else {
        return [...prevSelected, index];
      }
    });
  };
  const handleInverterDashboard = async (id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, invDashbrd: true }));
      const res = await invService.getInverterDashboard(id);
      if (res.status === 200) {
        setInvDashboard(res.data);
      }
      setLoadingState((prevState) => ({ ...prevState, invDashbrd: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, invDashbrd: false }));
      handleFetchError(error, "inverter dashboard data");
    }
  };

  const isSelected = (index) => selectedInverters.includes(index);
  const handleSelectAll = (isChecked) => {
    if (isChecked) {
      setSelectedInverters(newInvArray.map((_, index) => index));
    } else {
      setSelectedInverters([]);
    }
  };

  const isAllSelected = selectedInverters.length === newInvArray.length;
  const handleCompareInv = (selectedInvertersIndexes) => {
    const selectedInverters = selectedInvertersIndexes.map(
      (index) => newInvArray[index]
    );
    history.push({
      pathname: `/iot-home/dev-monitoring/inv-dashboard/compare-inv`,
      state: {
        invList: selectedInverters,
        activeInvField: deviceFieldValue,
      },
    });
  };
  const handleGHIOverview = async (id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, ghiOverview: true }));
      const res = await invService.getGHIOverview(id);
      if (res.status === 200) {
        setGHIOverview(res.data);
      }
      setLoadingState((prevState) => ({ ...prevState, ghiOverview: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, ghiOverview: false }));
      handleFetchError(error, "GHI Overview");
    }
  };

  return (
    <div className="home-container">
      <Grid className="grid-container" container>
        <Grid
          container
          className="inv_names_container"
          sx={{
            padding: "1rem !important",
            backgroundColor: "white !important",
            border: "0.0625rem solid #D8D8D8 !important",
            borderRadius: "0.75rem !important",
          }}
        >
          <Grid
            item
            lg={12}
            xs={12}
            sm={12}
            md={12}
            className="only_flex-start"
          >
            <Grid container className="inv-names_grid">
              {newInvArray.length > 0 ? (
                <>
          <Grid item xs={12} className="a-center_j-flex-start">
            <div onClick={handlePrevPage} className="arrow-btn" 
            style={{backgroundColor:currentPage === 0 ? "#ebe7e7" : "#2F67F8",
              border:"1px solid #D8D8D8", cursor:currentPage === 0 ? "not-allowed" : "pointer",
              pointerEvents:currentPage === 0 ? "none" : "auto"
            }}>
              <ChevronLeftIcon sx={{color:currentPage === 0 ? "#b5afaf" : "white"}} />
            </div>
          </Grid>
          {visibleInverters.map((ele, index) => (
            <Grid
              item
              key={index}
              lg={2.2}
              xs={12}
              sm={5.8}
              md={2.9}
              onClick={() => handleInverterClick(ele, index)}
              sx={{ flex: '0 0 auto', width: 'auto' }}
              className="flex-start_gap"
            >
              <Paper
                className="inv-names_paper"
                sx={{
                  backgroundColor: index === 0 ? "#2F67F8" : "#ffff",
                  border: '0.0625rem solid #D8D8D8 !important',
                }}
              >
                <div
                  className="inv-names_div"
                  style={{
                    justifyContent: 'center',
                    backgroundColor: index === 0 ? "#2F67F8" : "#ffff",
                  }}
                >
                  <p
                    className="Poppins_18px_rem"
                    style={{
                      color: index === 0 ? "#ffff" : "rgb(47, 103, 248)",
                    }}
                  >
                   {ele?.device_details?.name.charAt(0).toUpperCase() +
                            ele?.device_details?.name.slice(1).toLowerCase()}
                  </p>
                </div>
              </Paper>
            </Grid>
          ))}

          <Grid item xs={12} className="a-center_j-flex-end">
            <div onClick={handleNextPage} className="arrow-btn"
             style={{backgroundColor:currentPage === totalPages - 1 ? "#ebe7e7" : "#2F67F8",
              border:"1px solid #D8D8D8", cursor:currentPage === totalPages - 1 ? "not-allowed" : "pointer",
              pointerEvents:currentPage === totalPages - 1 ? "none" : "auto"
            }}>
              <ChevronRightIcon sx={{color:currentPage === totalPages - 1 ? "#b5afaf" : "white"}} />
            </div>
          </Grid>
        </>
              ) 
              : (
                <div className="no-data">
                  <p className="Poppins_16px_rem" style={{ fontWeight: "400" }}>
                    No data is available!
                  </p>
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          lg={2.4}
          xs={12}
          sm={12}
          md={12}
          className="total_power_container"
          sx={{
            height: gridHeight,
            position: "relative",
          }}
        >
          <Paper className="inv_total_paper">
            <p className="Poppins_24px_rem">Inverters</p>
            <div className="total_power_yield">
              <div className="justify_space_betwn">
                <div className="column_flex-start">
                  <p className="raleway_14px_rem">
                    Power Generated At Present{" "}
                  </p>
                  <p className="raleway_32px_rem">
                    {invDashboard.total_output_power_kW !== null &&
                    invDashboard.total_output_power_kW !== undefined
                      ? `${invDashboard.total_output_power_kW.toFixed(2)} `
                      : "--"}
                    <span className="Poppins_18px_rem">kW</span>
                  </p>
                </div>
                <div></div>
              </div>

              <div className="justify_space_betwn">
                <div className="column_flex-start">
                  <p className="raleway_14px_rem">
                    Total Yield Energy Till Now
                  </p>
                  <p className="raleway_32px_rem">
                    {invDashboard.total_yield_energy_kWh !== null &&
                    invDashboard.total_yield_energy_kWh !== undefined
                      ? `${invDashboard.total_yield_energy_kWh.toFixed(2)} `
                      : "--"}
                    <span className="Poppins_18px_rem">kWh</span>
                  </p>
                </div>
                <div></div>
              </div>
            </div>
          </Paper>
          {loadingState.invDashbrd && (
            <SimpleBackdrop open={loadingState.invDashbrd} />
          )}
        </Grid>
        <Grid
          ref={gridRef}
          item
          lg={9.5}
          xs={12}
          sm={12}
          md={12}
          className="center_center_column"
        >
          <Grid
            sx={{ position: "relative" }}
            container
            className="center_center_flex_gap"
          >
            <Grid
              item
              lg={11.9}
              xs={12}
              sm={12}
              md={11.9}
              className="flex-center_transparent"
            >
              <Paper className="gen-power_paper">
                <p className="Poppins_24px_rem">Generated Power</p>
              </Paper>
            </Grid>
            <Grid
              item
              lg={2.8}
              xs={12}
              sm={2.8}
              md={2.8}
              className="center_center_flex"
            >
              <Paper className="column_gap_transparent">
                <EnergyData
                  ACPercent={
                    invDashboard?.today_forcasted_increase_percent
                      ?.percentage !== null &&
                    invDashboard?.today_forcasted_increase_percent
                      ?.percentage !== undefined
                      ? invDashboard.today_forcasted_increase_percent.percentage.toFixed(
                          1
                        )
                      : "--"
                  }
                  ACValue={
                    invDashboard?.today_forcasted_increase_percent?.value !==
                      null &&
                    invDashboard?.today_forcasted_increase_percent?.value !==
                      undefined
                      ? invDashboard?.today_forcasted_increase_percent?.value.toFixed(
                          1
                        )
                      : "--"
                  }
                  ACEnergy={
                    invDashboard.today_forcasted_energy_kWh !== null &&
                    invDashboard.today_forcasted_energy_kWh !== undefined
                      ? invDashboard.today_forcasted_energy_kWh.toFixed(1)
                      : "--"
                  }
                  DCEnergy="true"
                  ghiValue={
                    ghiOverview.today_forcasted_ghi_irradiance_kWh_m2 !==
                      null &&
                    ghiOverview.today_forcasted_ghi_irradiance_kWh_m2 !==
                      undefined
                      ? ghiOverview.today_forcasted_ghi_irradiance_kWh_m2.toFixed(
                          2
                        )
                      : "--"
                  }
                  ghiPercent={
                    ghiOverview?.today_forcasted_increase_percent
                      ?.percentage !== null &&
                    ghiOverview?.today_forcasted_increase_percent
                      ?.percentage !== undefined
                      ? ghiOverview.today_forcasted_increase_percent.percentage.toFixed(
                          2
                        )
                      : "--"
                  }
                  ghiVal={
                    ghiOverview?.today_forcasted_increase_percent?.value !==
                      null &&
                    ghiOverview?.today_forcasted_increase_percent?.value !==
                      undefined
                      ? ghiOverview?.today_forcasted_increase_percent?.value.toFixed(
                          2
                        )
                      : "--"
                  }
                  unit="kWh"
                  title="Today’s Forecast"
                  colorCode="#00529A"
                />
              </Paper>
            </Grid>
            <Divider className="divider_14rem" orientation="vertical" />
            <Grid
              item
              lg={2.8}
              xs={12}
              sm={2.8}
              md={2.8}
              className="center_center_flex"
            >
              <Paper className="column_gap_transparent">
                <EnergyData
                  ACPercent={
                    invDashboard?.today_increase_percent?.percentage !== null &&
                    invDashboard?.today_increase_percent?.percentage !==
                      undefined
                      ? invDashboard.today_increase_percent.percentage.toFixed(
                          1
                        )
                      : "--"
                  }
                  ACValue={
                    invDashboard?.today_increase_percent?.value !== null &&
                    invDashboard?.today_increase_percent?.value !== undefined
                      ? invDashboard?.today_increase_percent?.value.toFixed(1)
                      : "--"
                  }
                  ACEnergy={
                    invDashboard.today_generated_energy_kWh !== null &&
                    invDashboard.today_generated_energy_kWh !== undefined
                      ? invDashboard.today_generated_energy_kWh.toFixed(1)
                      : "--"
                  }
                  DCEnergy="true"
                  ghiValue={
                    ghiOverview.today_ghi_irradiance_kWh_m2 !== null &&
                    ghiOverview.today_ghi_irradiance_kWh_m2 !== undefined
                      ? ghiOverview.today_ghi_irradiance_kWh_m2.toFixed(2)
                      : "--"
                  }
                  ghiPercent={
                    ghiOverview?.today_increase_percent?.percentage !== null &&
                    ghiOverview?.today_increase_percent?.percentage !==
                      undefined
                      ? ghiOverview.today_increase_percent.percentage.toFixed(2)
                      : "--"
                  }
                  ghiVal={
                    ghiOverview?.today_increase_percent?.value !== null &&
                    ghiOverview?.today_increase_percent?.value !== undefined
                      ? ghiOverview?.today_increase_percent?.value.toFixed(2)
                      : "--"
                  }
                  unit="kWh"
                  title="Today’s Generation"
                  colorCode="#FDA737"
                />
              </Paper>
            </Grid>
            <Divider className="divider_14rem" orientation="vertical" />
            <Grid
              item
              lg={2.8}
              xs={12}
              sm={2.8}
              md={2.8}
              className="center_center_flex"
            >
              <Paper className="column_gap_transparent">
                <EnergyData
                  ACPercent={
                    invDashboard?.yesterday_increase_percent?.percentage !==
                      null &&
                    invDashboard?.yesterday_increase_percent?.percentage !==
                      undefined
                      ? invDashboard?.yesterday_increase_percent?.percentage.toFixed(
                          1
                        )
                      : "--"
                  }
                  ACValue={
                    invDashboard?.yesterday_increase_percent?.value !== null &&
                    invDashboard?.yesterday_increase_percent?.value !==
                      undefined
                      ? invDashboard?.yesterday_increase_percent?.value.toFixed(
                          1
                        )
                      : "--"
                  }
                  ACEnergy={
                    invDashboard.yesterdays_generated_energy_kWh !== null &&
                    invDashboard.yesterdays_generated_energy_kWh !== undefined
                      ? invDashboard.yesterdays_generated_energy_kWh.toFixed(1)
                      : "--"
                  }
                  DCEnergy="true"
                  ghiValue={
                    ghiOverview.yesterday_ghi_irradiance_kWh_m2 !== null &&
                    ghiOverview.yesterday_ghi_irradiance_kWh_m2 !== undefined
                      ? ghiOverview.yesterday_ghi_irradiance_kWh_m2.toFixed(2)
                      : "--"
                  }
                  ghiPercent={
                    ghiOverview?.yesterday_increase_percent?.percentage !==
                      null &&
                    ghiOverview?.yesterday_increase_percent?.percentage !==
                      undefined
                      ? ghiOverview.yesterday_increase_percent.percentage.toFixed(
                          2
                        )
                      : "--"
                  }
                  ghiVal={
                    ghiOverview?.yesterday_increase_percent?.value !== null &&
                    ghiOverview?.yesterday_increase_percent?.value !== undefined
                      ? ghiOverview?.yesterday_increase_percent?.value.toFixed(
                          2
                        )
                      : "--"
                  }
                  unit="kWh"
                  title="Yesterday’s Generation"
                  colorCode="#04724D"
                />
              </Paper>
            </Grid>
            <Divider className="divider_14rem" orientation="vertical" />
            <Grid
              item
              lg={2.8}
              xs={12}
              sm={2.8}
              md={2.8}
              className="center_center_flex"
            >
              <Paper className="column_gap_transparent">
                <EnergyData
                  ACPercent={
                    invDashboard?.monthly_increase_percent?.percentage !==
                      null &&
                    invDashboard?.monthly_increase_percent?.percentage !==
                      undefined
                      ? invDashboard?.monthly_increase_percent?.percentage.toFixed(
                          1
                        )
                      : "--"
                  }
                  ACValue={
                    invDashboard?.monthly_increase_percent?.value !== null &&
                    invDashboard?.monthly_increase_percent?.value !== undefined
                      ? invDashboard?.monthly_increase_percent?.value.toFixed(1)
                      : "--"
                  }
                  ACEnergy={
                    invDashboard.past_30days_generated_energy_kWh
                      ? invDashboard.past_30days_generated_energy_kWh.toFixed(1)
                      : "--"
                  }
                  ghiValue={
                    ghiOverview.past_30days_ghi_irradiance_kWh_m2 !== null &&
                    ghiOverview.past_30days_ghi_irradiance_kWh_m2 !== undefined
                      ? ghiOverview.past_30days_ghi_irradiance_kWh_m2.toFixed(2)
                      : "--"
                  }
                  ghiPercent={
                    ghiOverview?.monthly_increase_percent?.percentage !==
                      null &&
                    ghiOverview?.monthly_increase_percent?.percentage !==
                      undefined
                      ? ghiOverview.monthly_increase_percent.percentage.toFixed(
                          2
                        )
                      : "--"
                  }
                  ghiVal={
                    ghiOverview?.monthly_increase_percent?.value !== null &&
                    ghiOverview?.monthly_increase_percent?.value !== undefined
                      ? ghiOverview?.monthly_increase_percent?.value.toFixed(2)
                      : "--"
                  }
                  DCEnergy="true"
                  unit="kWh"
                  title="Monthly Generation"
                  colorCode="yellowgreen"
                />
              </Paper>
            </Grid>

            {loadingState.invDashbrd && (
              <SimpleBackdrop open={loadingState.invDashbrd} />
            )}
          </Grid>
        </Grid>
        <Grid
          item
          lg={8}
          xs={12}
          sm={12}
          md={12}
          className="donut_grid_exc"
          id="power_analysis_id"
          sx={{
            height: {
              xs: "auto",
              sm: "auto",
              md: gridHeightTwo,
              lg: gridHeightTwo,
              xl: gridHeightTwo,
              xxl: gridHeightTwo,
            },
          }}
        >
          <Paper className="power_analysis_paper">
            <p className="Poppins_18px_rem">Inverter Power Analysis</p>
            <div className="analysis_div">
              <CustomTooltip
                title={
                  <React.Fragment>
                    <Typography className="tooltip_typography">
                      <CircleIcon
                        className="circle_container"
                        sx={{ color: "green" }}
                      />
                      <p className="Poppins_14px_rem">
                        <p className="blue_text">Total Power Generated:</p>{" "}
                        {totalPowerGen} kW
                      </p>
                    </Typography>
                    <Typography className="tooltip_typography">
                      <CircleIcon
                        className="circle_container"
                        sx={{ color: "#eeeeee", border: "0.5px solid gray" }}
                      />
                      <p className="Poppins_14px_rem">
                        <p className="blue_text">Total Capacity:</p>{" "}
                        {totalCapacity} kW
                      </p>
                    </Typography>
                  </React.Fragment>
                }
                arrow
              >
                <div>
                  <p className="Poppins_16px_rem">Total Power Generated :</p>
                  <BorderLinearProgress
                        variant="determinate"
                        value={
                          totalCapacity !== 0
                            ? (totalPowerGen / totalCapacity) * 100
                            : 0
                        }
                      />
                </div>
              </CustomTooltip>
            </div>
          </Paper>
          {loadingState.invList && (
            <SimpleBackdrop open={loadingState.invList} />
          )}
          <Grid
            container
            className="inv_grid"
            id="inv_grid_between"

          >
            {newInvArray
              .sort((a, b) => {
                return a.device_details.name.localeCompare(
                  b.device_details.name
                );
              })
              .map(
                (item, index) =>
                  item.device_details.name !== null && (
                    <Grid
                      item
                      xs={12}
                      lg={5.9}
                      md={5.9}
                      sm={12}
                      key={index}
                      className="inv_grid"
                    >
                      <Paper className="inv_paper">
                        <CustomTooltip
                          title={
                            <React.Fragment>
                              <Typography className="tooltip_typography">
                                <CircleIcon
                                  className="circle_container"
                                  sx={{ color: "green" }}
                                />
                                <p className="Poppins_14px_rem">
                                  <p className="blue_text">Generated Power:</p>
                                  {item.device_data === null ||
                                  item.device_data["output_power_kW"] ===
                                    null ||
                                  item.device_data["output_power_kW"] === 0
                                    ? 0
                                    : item.device_data["output_power_kW"]}{" "}
                                  kW
                                </p>
                              </Typography>
                              <Typography className="tooltip_typography">
                                <CircleIcon
                                  className="circle_container"
                                  sx={{
                                    color: "#eeeeee",
                                    border: "0.5px solid gray",
                                  }}
                                />
                                <p className="Poppins_14px_rem">
                                  <p className="blue_text">
                                    Inverter Capacity:
                                  </p>
                                  {item.device_details
                                        ?.device_specific_details?.capacity_kW
                                        ? item.device_details
                                            .device_specific_details.capacity_kW
                                        : 0}
                                  kW
                                </p>
                              </Typography>
                            </React.Fragment>
                          }
                          arrow
                        >
                          <p
                            className="Poppins_16px_rem"
                            style={{ fontWeight: "400" }}
                          >
                            {item.device_details.name}
                          </p>
                          <BorderLinearProgress
                            variant="determinate"
                            value={
                              (item?.device_data === null ||
                              item?.device_data["output_power_kW"] === null ||
                              item?.device_data["output_power_kW"] === 0
                                ? 0
                                : item?.device_data["output_power_kW"] /
                                  item?.device_details?.device_specific_details?.capacity_kW) * 100
                            }
                          />
                        </CustomTooltip>
                      </Paper>
                    </Grid>
                  )
              )}
          </Grid>
        </Grid>

        <Grid
          item
          lg={3.9}
          xs={12}
          md={12}
          sm={12}
          className="donut_grid_exc"
          id="power_analysis_id"
          ref={gridRefTwo}
        >
          <Paper className="energy-paper">
            <p className="Poppins_18px_rem">Generated Power</p>
          </Paper>
          <Paper className="energy-paper center_div_nogap">
            <CustomCircularBar
              centerText={totalPowerGen}
              values={newInvArray
                .filter((item) => item.device_details !== null)
                .map((device) =>
                  device.device_data === null ||
                  device.device_data["output_power_kW"] === null ||
                  device.device_data["output_power_kW"] === 0
                    ? 0
                    : device.device_data["output_power_kW"]
                )}
              nameData={inverterNames}
              unit="kW"
            />
          </Paper>
          {loadingState.invList && (
            <SimpleBackdrop open={loadingState.invList} />
          )}
        </Grid>

        <Grid item lg={12} xs={12} sm={12} md={12} className="imp_notificatn">
          <Paper className="imp_note_paper">
            <div className="imp_note_padding">
              <p className="Poppins_24px_rem">Important Notifications</p>
            </div>
            <Divider className="divider_mt_border" />
          </Paper>
          {/* {NotificationData.map((ele, index) => ( */}
          <Paper
            // key={index}
            className="flex-start_baseline"
            // sx={{
            //   height: "100%",
            //   width: "100% ! important",
            //   boxShadow: "none !important",
            //   display: "flex",
            //   justifyContent: "baseline",
            //   alignItems: "flex-start",
            //   // borderBottom:
            //   //   index === NotificationData.length - 1
            //   //     ? "none"
            //   //     : "2px solid #D9D9D9",
            // }}
          >
            <p className="Poppins_18px_rem">No Notification yet</p>
            {/* <div
                    style={{
                      padding: "1vh 2vw",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-end",
                      gap: "0.5vw",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "baseline",
                      }}
                    >
                      <WarningRoundedIcon style={{ color: "#FFA287" }} />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "baseline",
                      }}
                    >
                      <p className="Poppins_18px_rem">
                        {ele.title}
                        <span style={{ fontWeight: "400" }}>
                          &nbsp;{ele.disc}
                        </span>
                      </p>
                    </div>
                  </div> */}
          </Paper>
          {/* ))} */}
        </Grid>
        <Grid
          item
          lg={12}
          xs={12}
          md={12}
          className="flex_border_borderRadius"
          sx={{
            position: "relative",
          }}
        >
          <Paper className="column_gap_radius_padding">
            <div className="inv_list">
              <p className="raleway_18px_rem">Inverters List</p>
              {selectedInverters.length >= 2 && (
                <div
                  className="compare_button"
                  onClick={() => handleCompareInv(selectedInverters)}
                >
                  <p className="raleway_12px_rem">Compare</p>
                </div>
              )}
            </div>
            <TableContainer
              className="table-scrollbar"
              component={Paper}
              sx={{
                overflowX: "auto",
                border: "1px solid #E4E4E4",
                borderRadius: "12px",
              }}
            >
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        backgroundColor: "#E6EEF5 !important",
                      }}
                    >
                      <div className="checkbox">
                        <input
                          type="checkbox"
                          checked={isAllSelected}
                          onChange={(e) => handleSelectAll(e.target.checked)}
                          style={{
                            cursor: "pointer",
                            accentColor: isAllSelected ? "#90E900" : "initial", // Set checkbox color
                            outlineColor: isAllSelected ? "#2F67F8" : "initial",
                          }}
                        />
                      </div>
                    </TableCell>
                    {columns.map((column) => (
                      <TableCell
                        sx={{
                          padding: "1rem",
                          backgroundColor: "#E6EEF5 !important",
                        }}
                        key={column.field}
                        align="left"
                      >
                        <p
                          className="table-text"
                          style={{ fontWeight: "700", fontFamily: "raleway" }}
                        >
                          {column.title}
                        </p>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {newInvArray
                    .filter(
                      (item) =>
                        item.device_details !== null &&
                        item.device_details.name !== null
                    )
                    .map((row, index) => (
                      <TableRow
                        key={index}
                        // selected={isSelected(index)}
                        sx={{
                          textTransform: "capitalize",
                          backgroundColor: isSelected(index)
                            ? "#FFFBE6"
                            : "transparent",
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ padding: "1rem" }}
                        >
                          <div className="checkbox">
                            <input
                              type="checkbox"
                              checked={isSelected(index)}
                              onChange={() => handleCheckboxClick(index)}
                              style={{
                                cursor: "pointer",
                                accentColor: isSelected(index)
                                  ? "#90E900"
                                  : "initial", // Set checkbox color
                                outlineColor: isSelected(index)
                                  ? "#2F67F8"
                                  : "initial",
                              }}
                            />
                          </div>
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="left"
                          sx={{ padding: "1rem" }}
                        >
                          <p
                            className="table-text"
                            style={{ color: "#2F67F8" }}
                            onClick={() => handleInverterClick(row)}
                          >
                            {row.device_details.name.charAt(0).toUpperCase() +
                              row.device_details.name.slice(1).toLowerCase()}
                          </p>
                        </TableCell>
                        <TableCell align="left">
                          <p className="table-text" style={{ padding: "1rem" }}>
                            --
                          </p>
                        </TableCell>
                        <TableCell align="left" sx={{ padding: "1rem" }}>
                        <p className="table-text">
  {row?.device_details?.device_specific_details?.capacity_kW 
    ? `${row.device_details.device_specific_details.capacity_kW} kW` 
    : "0 kW"}
</p>

                        </TableCell>
                        <TableCell align="left" sx={{ padding: "1rem" }}>
                          {row?.device_data !== null &&
                          row?.device_data["output_power_kW"] !== null ? (
                            <p className="table-text">{`${row?.device_data?.output_power_kW} kW`}</p>
                          ) : (
                            <p className="table-text">0 kW</p>
                          )}
                        </TableCell>
                        <TableCell align="left" sx={{ padding: "1rem" }}>
                          <p className="table-text">
                            {row?.device_details?.status === true
                              ? "ON"
                              : "OFF"}
                          </p>
                        </TableCell>
                        <TableCell align="left" sx={{ padding: "1rem" }}>
                        <p className="table-text">
  {row?.device_details?.last_active?.match(/^(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2})/)
    ? row.device_details.last_active.match(/^(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2})/)
        .slice(1)
        .join(" ")
    : "--"}
</p>

                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          {loadingState.invList && (
            <SimpleBackdrop open={loadingState.invList} />
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default InverterDashboard;
