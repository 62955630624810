import React, { useState } from "react";
import Button from "@mui/material/Button";
import "./ForgotPass.scss";
import SimpleBackdrop from "../LifeScience/components/common/SimpleBackdrop";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { UserService } from "../IOT/Services/UserService";
const userService = new UserService();
function ForgotPass() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const validateFields = () => {
    let valid = true;
    if (!email) {
      setEmailError("Please enter your email address");
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Please enter a valid email address");
      valid = false;
    } else {
      setEmailError("");
    }
    return valid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (validateFields()) {
      const data = new FormData(event.currentTarget);
      let reqData = {
        email: data.get("email"),
      };
      await userService
        .generateOtp(reqData)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            setLoading(false);

            enqueueSnackbar("OTP is sent on your registered email", {
              variant: "success",
            });
            history.push({
              pathname: `/verify-pass`,
              state: { id: res.data.id, email: res.data.email },
            });
          } else if (res.status === 422) {
            enqueueSnackbar("user does not exist or inactive user", {
              variant: "warning",
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log("Error", err);
          enqueueSnackbar("user does not exist or inactive user", { variant: "error" });
        });
    } else {
      setLoading(false);
    }
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  return (
    <form className="formContainer" onSubmit={handleSubmit}>
    {loading && <SimpleBackdrop open={loading} />}
      <div className="signUptoplogo">
        <img src="https://s3.amazonaws.com/smarttrak.co/landing-images/smarttraklogo.svg" width={160} alt="logo" />
      </div>
      <div className="main-container">
        <div className="left-container">
          <img src="https://s3.amazonaws.com/smarttrak.co/images/forgot-pass.png"  className="pass_logo" alt="password-logo" />
        </div>
        <div className="right-container">
          <div className="forgotPasswordContainer">
            <span className="forgotPasswordText">Forgot Password</span>
          </div>
          <p className="paragraph">
            Your security is important to us. If you need to reset your
            password, enter your email and we'll walk you through the process.
          </p>

          <input
            type="text"
            name="email"
            id="email"
            value={email}
            placeholder="Email"
            onChange={handleEmailChange}
            className={`input ${emailError ? "error" : ""}`}
            style={{
              border: "1px solid black",
              width: "88%",
              marginLeft: "0px",
            }}
          />
          {emailError && <div className="error-message">{emailError}</div>}

          <Button
            type="submit"
            variant="contained"
            style={{
              backgroundColor: "#1C629E",
              width: "88%",
              marginTop: "40px",
            }}
          >
            Submit
          </Button>
          <p className="backToLogin">
            <a
              href="/signin"
              style={{
                textDecoration: "none",
                fontFamily: "Poppins",
                fontSize: "17px",
                color: "#000000",
              }}
            >
              Back to Login
            </a>
          </p>
        </div>
      </div>
    </form>
  );
}

export default ForgotPass;