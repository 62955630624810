import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import "./SideBarIot.scss";
import Tooltip from "@mui/material/Tooltip";
import { Box } from "@mui/material";
import Paper from "@mui/material/Paper";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import DashboardIcon from "../SVG/DashboardIcon";
import MonitoringIcon from "../SVG/MonitoringIcon";
import ReportsIcon from "../SVG/ReportsIcon";
import AlarmssIcon from "../SVG/AlarmsIcon";
import ReportFailureIcon from "../SVG/ReportsFailureIcon";
import AbtIcon from "../SVG/AbtIcon";
import PlantViewIcon from "../SVG/PlantViewIcon";
import { CosmicInverterService } from "../Services/CosmicInverterService";
import InverterIcon from "../SVG/InverterIcon";
import ThreeDIcon from "../SVG/ThreeDIcon";
import useErrorHandler from "../../utils/errorHandler";

const invService = new CosmicInverterService();
const SideBar = (props) => {
  const { height } = props;
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("Dashboard");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedTab, setSelectedTab] = useState(null);
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);
  const [newInvArray, setNewInvArray] = useState([]);
  const [deviceFieldValue, setDeviceFieldValue] = useState([]);
  const [deviceFieldValueWMS, setDeviceFieldValueWMS] = useState([]);

  
  const [tableData, setTableData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const drawerRef = useRef(null);
  let warningDisplayed = false;
  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    "& .MuiDrawer-paper": {
      position: "relative",
      whiteSpace: "nowrap",
      width: isOpenSidebar ? "13rem" : "5rem",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: "border-box",
      ...(!open && {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
          width: theme.spacing(9),
        },
      }),
    },
  }));
  const handleFetchError = useErrorHandler();

  const storedPlantIds = localStorage.getItem("plantIds");
  const plantIdsArray = JSON.parse(storedPlantIds);
  const id = plantIdsArray[0];
  useEffect(() => {
    if (id) {
      handleMfmParameters(id);
    }
  }, [id]);
  useEffect(() => {
    handleInvertersList(id)
    setActiveTabBasedOnLocation(location.pathname);
  }, [location.pathname]);
  useEffect(() => {
    handleFieldsList();
    handleFieldsListWMS();
  }, []);
  const setActiveTabBasedOnLocation = (pathname) => {
    const tabMapping = {
      "/iot-home": "Dashboard",
      "/iot-home/reports": "Reports",
      "/iot-home/alarms": "Alarms",
      "/iot-home/reports-failure": "Reports Failure",
      "/iot-home/abt-meter": "ABT Meter",
      "/iot-home/plant-layout": "Plant Layout",
      "/iot-home/three-d-twin": "3D",
      "/iot-home/dev-monitoring/inv-dashboard": "Monitoring",
      "/iot-home/dev-monitoring/mfm": "Monitoring",
      "/iot-home/dev-monitoring/mfm-data": "Monitoring",
      "/iot-home/dev-monitoring/smb": "Monitoring",
      "/iot-home/dev-monitoring/smb/smb-details": "Monitoring",
      "/iot-home/dev-monitoring/trackers": "Monitoring",
      "/iot-home/dev-monitoring/trackers/home": "Monitoring",
      "/iot-home/dev-monitoring/vcb-panel": "Monitoring",
      "/iot-home/dev-monitoring/vcb/vcb-details": "Monitoring",
      "/iot-home/dev-monitoring/ht-panel": "Monitoring",
      "/iot-home/dev-monitoring/weather-dashboard": "Monitoring",
      "/iot-home/dev-monitoring/inv-dashboard/compare-inv": "Monitoring",
      "/iot-home/dev-monitoring/inv-dashboard/inv-details": "Monitoring",
    };
    const activeTab = tabMapping[pathname] || "";
    setActiveTab(activeTab);
    const deviceTabMapping = {
      "/iot-home/dev-monitoring/inv-dashboard": "Inverter",
      "/iot-home/dev-monitoring/inv-dashboard/inv-details": "Inverter",
      "/iot-home/dev-monitoring/inv-dashboard/compare-inv": "Inverter",
      "/iot-home/dev-monitoring/mfm": "MFM",
      "/iot-home/dev-monitoring/mfm-data": "MFM",
      "/iot-home/dev-monitoring/smb": "SMB",
      "/iot-home/dev-monitoring/smb/smb-details": "SMB",
      "/iot-home/dev-monitoring/trackers": "Trackers",
      "/iot-home/dev-monitoring/trackers/home": "Trackers",
      "/iot-home/dev-monitoring/vcb-panel": "VCB Panel",
      "/iot-home/dev-monitoring/vcb/vcb-details": "VCB Panel",
      "/iot-home/dev-monitoring/ht-panel": "HT Panel",
      "/iot-home/dev-monitoring/weather-dashboard": "Weather Dashboard",
    };
    const selectedTab = deviceTabMapping[pathname];
    setSelectedTab(selectedTab);
  };
  useEffect(() => {
    handleMenuItemClick(setActiveTab)
  }, [setActiveTab]);

  const monitoringBoxStyle = {
    position: "absolute",
    width: "auto",
    minHeight: "100vh",
    maxHeight: "100vh",
    bgcolor: "background.paper",
    boxShadow: "none",
    left: isOpenSidebar ? "3.4rem" : "5rem",
    p: 4,
    top: "-8vh",
    padding: "1rem",
    zIndex: "5",
    overflowY: "auto",
    border: "1px solid #D8D8D8"
  };
  const handleOutsideClick = (e) => {
    if (drawerRef.current && !drawerRef.current.contains(e.target)) {
      setOpenDrawer(false);
    }
  };
  const handleInvertersList = async (id) => {
    try {
      setLoading(true);
      const res = await invService.getDeviceLevelData(id,"inverter");
      if (res.status === 200) {
        setNewInvArray(res.data);
        setLoading(false);

      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // if (error.response && error.response.status === 500){
        //   history.push("/iot-home/server-error")
        //    setLoading(false);
        //  }
        if (!warningDisplayed) {
          enqueueSnackbar("No Active Inverter Found", {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          });
          warningDisplayed = true;
        }

        setLoading(false);
      } else {
        console.log("Error while fetching the InvertersList data", error);
        if (!warningDisplayed) {
          enqueueSnackbar("Error while Fetching the InvertersList Data", {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          });
          warningDisplayed = true;
        }
        setLoading(false);
      }
    }
  };
  
  const handleArrowClick = () => {
    setIsOpenSidebar(!isOpenSidebar)
  }
  useEffect(() => {
    if (openDrawer) {
      document.addEventListener('mousedown', handleOutsideClick); // Add listener when drawer is open
    } else {
      document.removeEventListener('mousedown', handleOutsideClick); // Remove listener when drawer is closed
    }
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick); // Cleanup listener on unmount
    };
  }, [openDrawer]);

  const devicesList = [
    {
      name: "Inverter",
      icon: InverterIcon,
      fillColor:"black",
      path1: "M20 4H19.5V1H22C22.276 1 22.5 0.776 22.5 0.5C22.5 0.224 22.276 0 22 0H16C15.724 0 15.5 0.224 15.5 0.5C15.5 0.776 15.724 1 16 1H18.5V4H5C2.519 4 0.5 6.019 0.5 8.5V15.5C0.5 17.456 1.754 19.124 3.5 19.743V21.5C3.5 21.776 3.724 22 4 22C4.276 22 4.5 21.776 4.5 21.5V19.972C4.664 19.99 4.831 20 5 20H20C20.169 20 20.336 19.99 20.5 19.972V21.5C20.5 21.776 20.724 22 21 22C21.276 22 21.5 21.776 21.5 21.5V19.743C23.246 19.124 24.5 17.456 24.5 15.5V8.5C24.5 6.019 22.481 4 20 4ZM23.5 15.5C23.5 17.43 21.93 19 20 19H5C3.07 19 1.5 17.43 1.5 15.5V8.5C1.5 6.57 3.07 5 5 5H20C21.93 5 23.5 6.57 23.5 8.5V15.5ZM2.5 2.5C2.5 2.224 2.724 2 3 2H9C9.276 2 9.5 2.224 9.5 2.5C9.5 2.776 9.276 3 9 3H3C2.724 3 2.5 2.776 2.5 2.5ZM17.5 8C15.294 8 13.5 9.794 13.5 12C13.5 14.206 15.294 16 17.5 16C19.706 16 21.5 14.206 21.5 12C21.5 9.794 19.706 8 17.5 8ZM17.5 15C16.016 15 14.781 13.917 14.542 12.5H17C17.276 12.5 17.5 12.276 17.5 12C17.5 11.724 17.276 11.5 17 11.5H14.542C14.781 10.083 16.016 9 17.5 9C19.154 9 20.5 10.346 20.5 12C20.5 13.654 19.154 15 17.5 15ZM7.5 8C5.294 8 3.5 9.794 3.5 12C3.5 14.5 5.5 16 7.5 16C9.706 16 11.5 14.206 11.5 12C11.5 9.794 9.706 8 7.5 8ZM7.5 15C6.853 15 6.254 14.793 5.763 14.444L7.5 12.707C7.695 12.512 7.695 12.195 7.5 12C7.305 11.805 6.988 11.805 6.793 12L5.056 13.737C4.706 13.247 4.5 12.647 4.5 12C4.5 10.346 5.846 9 7.5 9C9.154 9 10.5 10.346 10.5 12C10.5 13.654 9.154 15 7.5 15Z"
    },
    {
      name: "MFM",
      icon: InverterIcon,
      fillColor:"black",
      path1: "M7.5 17H17.5V7H7.5V17ZM8.5 8H16.5V16H8.5V8ZM24.5 8V7H22.5V4.5C22.5 3.12 21.38 2 20 2H17.5V0H16.5V2H14.5V0H13.5V2H11.5V0H10.5V2H8.5V0H7.5V2H5C3.62 2 2.5 3.12 2.5 4.5V7H0.5V8H2.5V10H0.5V11H2.5V13H0.5V14H2.5V16H0.5V17H2.5V19.5C2.5 20.88 3.62 22 5 22H7.5V24H8.5V22H10.5V24H11.5V22H13.5V24H14.5V22H16.5V24H17.5V22H20C21.38 22 22.5 20.88 22.5 19.5V17H24.5V16H22.5V14H24.5V13H22.5V11H24.5V10H22.5V8H24.5ZM20 21H5C4.17 21 3.5 20.33 3.5 19.5V4.5C3.5 3.67 4.17 3 5 3H20C20.83 3 21.5 3.67 21.5 4.5V19.5C21.5 20.33 20.83 21 20 21Z"
    },

    {
      name: "SMB",
      icon: InverterIcon,
      fillColor:"black",
      path1:"M20 0H3C1.622 0 0.5 1.122 0.5 2.5V17.5C0.5 18.708 1.36 19.717 2.5 19.95V24H20.5V19.95C21.64 19.718 22.5 18.708 22.5 17.5V2.5C22.5 1.122 21.378 0 20 0ZM3.5 23V20H19.5V23H3.5ZM21.5 17.5C21.5 18.327 20.827 19 20 19H3C2.173 19 1.5 18.327 1.5 17.5V2.5C1.5 1.673 2.173 1 3 1H20C20.827 1 21.5 1.673 21.5 2.5V17.5ZM4.5 12H18.5V4H4.5V12ZM5.5 5H17.5V11H5.5V5ZM6.5 15.5C6.5 16.052 6.052 16.5 5.5 16.5C4.948 16.5 4.5 16.052 4.5 15.5C4.5 14.948 4.948 14.5 5.5 14.5C6.052 14.5 6.5 14.948 6.5 15.5ZM8.5 15H18.5V16H8.5V15Z"
    },
    {
      name: "Trackers",
      icon: InverterIcon,
      fillColor:"black",
      fillColorPath2:"white",
      strokeColorPath2:"black",
      path1:"M12.5016 0.363281C7.75248 0.363281 3.89062 4.2251 3.89062 8.97419C3.89062 10.7124 4.39975 12.3524 5.30884 13.7233L6.2943 12.7378C5.62517 11.6287 5.25066 10.3415 5.25066 8.97419C5.25066 4.97419 8.50161 1.72328 12.5016 1.72328C16.498 1.72328 19.7488 4.97419 19.7488 8.97419C19.7488 10.4251 19.327 11.7887 18.5779 12.9415L19.5561 13.9196C20.5488 12.5087 21.1088 10.796 21.1088 8.97419C21.1088 4.2251 17.247 0.363281 12.5016 0.363281ZM14.9197 15.8106L12.5016 20.6142L10.0797 15.8106C9.9011 15.7238 8.8927 15.4581 7.70157 14.4142L6.73793 15.3778C7.42157 15.9924 8.21433 16.5015 9.0943 16.8796L12.5016 23.636L15.9052 16.8796C16.7088 16.5342 17.4434 16.0796 18.087 15.5342L17.1198 14.5669C16.0843 15.4135 15.0045 15.7703 14.9197 15.8106Z",
      path2:"M16.7366 8.76381C16.7366 11.0999 14.834 12.9984 12.5021 12.9984C10.1658 12.9984 8.26758 11.1002 8.26758 8.76381C8.26758 6.42748 10.1658 4.5293 12.5021 4.5293C14.834 4.52926 16.7366 6.42779 16.7366 8.76381Z"
    },
    {
      name: "VCB Panel",
      icon: InverterIcon,
      fillColor:"black",
      path1:"M22.517 5.83145L15.017 0.771445C13.489 -0.260555 11.513 -0.261555 9.983 0.771445L2.483 5.83145C1.241 6.66945 0.5 8.06345 0.5 9.56045V19.4994C0.5 21.9804 2.519 23.9994 5 23.9994H8.573C9.336 23.9994 10.021 23.5754 10.362 22.8944L12.447 18.7234C12.525 18.5684 12.516 18.3844 12.425 18.2374C12.334 18.0904 12.173 18.0004 12 18.0004H8.467C8.092 18.0004 7.761 17.8034 7.582 17.4744C7.403 17.1454 7.419 16.7604 7.601 16.4784L13.065 9.35345C13.347 8.92344 14.04 8.90445 14.346 9.31745C14.495 9.51845 14.538 9.77145 14.487 9.95245L12.544 14.2974C12.475 14.4514 12.489 14.6314 12.581 14.7734C12.673 14.9164 12.831 15.0024 13 15.0024H16.659C16.938 15.0024 17.191 15.1324 17.353 15.3594C17.515 15.5864 17.555 15.8674 17.463 16.1334L15.472 22.0214C15.315 22.4854 15.389 22.9784 15.674 23.3754C15.955 23.7684 16.411 24.0014 16.893 24.0014H19.999C22.48 24.0014 24.499 21.9824 24.499 19.5014V9.56145C24.499 8.06445 23.758 6.66945 22.516 5.83245L22.517 5.83145ZM23.5 19.5004C23.5 21.4304 21.93 23.0004 20 23.0004H16.894C16.731 23.0004 16.583 22.9244 16.488 22.7914C16.393 22.6594 16.368 22.4944 16.421 22.3404L18.411 16.4554C18.609 15.8834 18.52 15.2704 18.168 14.7774C17.816 14.2834 17.267 14.0004 16.66 14.0004H13.771L15.422 10.3014C15.589 9.75644 15.489 9.18045 15.15 8.72245C14.811 8.26345 14.289 8.00045 13.718 8.00045C13.115 8.00045 12.559 8.30145 12.251 8.77345L6.786 15.9004C6.378 16.5264 6.348 17.2934 6.704 17.9504C7.06 18.6074 7.72 19.0004 8.468 19.0004H11.192L9.468 22.4474C9.298 22.7884 8.955 23.0004 8.573 23.0004H5C3.07 23.0004 1.5 21.4304 1.5 19.5004V9.56145C1.5 8.39745 2.077 7.31245 3.042 6.66045L10.542 1.59945C11.731 0.796445 13.268 0.796445 14.457 1.59945L21.957 6.66045C22.923 7.31245 23.499 8.39745 23.499 9.56145L23.5 19.5004Z"
    },
    {
      name: "HT Panel",
      icon: InverterIcon,
      fillColor:"black",
      path1:"M22.517 5.83145L15.017 0.771445C13.489 -0.260555 11.513 -0.261555 9.983 0.771445L2.483 5.83145C1.241 6.66945 0.5 8.06345 0.5 9.56045V19.4994C0.5 21.9804 2.519 23.9994 5 23.9994H8.573C9.336 23.9994 10.021 23.5754 10.362 22.8944L12.447 18.7234C12.525 18.5684 12.516 18.3844 12.425 18.2374C12.334 18.0904 12.173 18.0004 12 18.0004H8.467C8.092 18.0004 7.761 17.8034 7.582 17.4744C7.403 17.1454 7.419 16.7604 7.601 16.4784L13.065 9.35345C13.347 8.92344 14.04 8.90445 14.346 9.31745C14.495 9.51845 14.538 9.77145 14.487 9.95245L12.544 14.2974C12.475 14.4514 12.489 14.6314 12.581 14.7734C12.673 14.9164 12.831 15.0024 13 15.0024H16.659C16.938 15.0024 17.191 15.1324 17.353 15.3594C17.515 15.5864 17.555 15.8674 17.463 16.1334L15.472 22.0214C15.315 22.4854 15.389 22.9784 15.674 23.3754C15.955 23.7684 16.411 24.0014 16.893 24.0014H19.999C22.48 24.0014 24.499 21.9824 24.499 19.5014V9.56145C24.499 8.06445 23.758 6.66945 22.516 5.83245L22.517 5.83145ZM23.5 19.5004C23.5 21.4304 21.93 23.0004 20 23.0004H16.894C16.731 23.0004 16.583 22.9244 16.488 22.7914C16.393 22.6594 16.368 22.4944 16.421 22.3404L18.411 16.4554C18.609 15.8834 18.52 15.2704 18.168 14.7774C17.816 14.2834 17.267 14.0004 16.66 14.0004H13.771L15.422 10.3014C15.589 9.75644 15.489 9.18045 15.15 8.72245C14.811 8.26345 14.289 8.00045 13.718 8.00045C13.115 8.00045 12.559 8.30145 12.251 8.77345L6.786 15.9004C6.378 16.5264 6.348 17.2934 6.704 17.9504C7.06 18.6074 7.72 19.0004 8.468 19.0004H11.192L9.468 22.4474C9.298 22.7884 8.955 23.0004 8.573 23.0004H5C3.07 23.0004 1.5 21.4304 1.5 19.5004V9.56145C1.5 8.39745 2.077 7.31245 3.042 6.66045L10.542 1.59945C11.731 0.796445 13.268 0.796445 14.457 1.59945L21.957 6.66045C22.923 7.31245 23.499 8.39745 23.499 9.56145L23.5 19.5004Z"
    },

    {
      name: "Weather Dashboard", 
      icon: InverterIcon,
      fillColor:"#393939",
      path1:"M7.6235 19H16.3765C16.5392 18.9955 16.6972 19.0549 16.8168 19.1654C16.9363 19.2758 17.0079 19.4287 17.0163 19.5913C17.0247 19.7538 16.9691 19.9132 16.8615 20.0354C16.7539 20.1575 16.6028 20.2328 16.4405 20.245L16.3765 20.248H7.6235C7.46078 20.2525 7.30276 20.1931 7.18321 20.0826C7.06366 19.9722 6.99206 19.8193 6.9837 19.6567C6.97535 19.4942 7.0309 19.3348 7.13849 19.2126C7.24608 19.0905 7.39718 19.0152 7.5595 19.003L7.6235 19ZM4.6235 16.5H19.376C19.5365 16.4992 19.691 16.5603 19.8076 16.6706C19.9242 16.7808 19.9939 16.9317 20.0021 17.092C20.0104 17.2522 19.9566 17.4095 19.8519 17.5311C19.7472 17.6527 19.5997 17.7293 19.44 17.745L19.376 17.748H4.624C4.46354 17.7488 4.30896 17.6877 4.19236 17.5774C4.07576 17.4672 4.0061 17.3163 3.99787 17.156C3.98963 16.9958 4.04344 16.8385 4.14812 16.7169C4.2528 16.5953 4.40031 16.5187 4.56 16.503L4.6235 16.5ZM12 3.5C15.1685 3.5 16.966 5.5975 17.2275 8.13H17.3075C19.3465 8.13 20.9995 9.779 20.9995 11.8125C20.9995 13.846 19.3465 15.495 17.3075 15.495H6.6925C4.653 15.495 3 13.8465 3 11.8125C3 9.7785 4.653 8.13 6.692 8.13H6.772C7.035 5.5805 8.831 3.5 12 3.5ZM12 4.7475C9.8695 4.7475 8.0125 6.4715 8.0125 8.8525C8.0125 9.23 7.6845 9.5265 7.3085 9.5265H6.5985C5.3015 9.5265 4.25 10.583 4.25 11.8865C4.25 13.1905 5.3015 14.2475 6.5985 14.2475H17.4015C18.6985 14.2475 19.75 13.1905 19.75 11.887C19.75 10.5835 18.6985 9.526 17.4015 9.526H16.6915C16.3155 9.526 15.9875 9.23 15.9875 8.853C15.9875 6.441 14.1305 4.748 12 4.748",
    },
  ]
  const sidebarTabs = [
    {
      name: "Dashboard",
      icon: DashboardIcon
    },
    {
      name: "Monitoring",
      icon: MonitoringIcon
    },
    {
      name: "Reports",
      icon: ReportsIcon
    },
    {
      name: "Alarms",
      icon: AlarmssIcon
    },
    {
      name: "Reports Failure",
      icon: ReportFailureIcon
    },
    {
      name: "ABT Meter",
      icon: AbtIcon
    },
    {
      name: "Plant Layout",
      icon: PlantViewIcon
    },
    {
      name: "3D",
      icon: ThreeDIcon
    }
  ]


  const handleMenuItemClick = (tab) => {
    setActiveTab(tab); // Update the active tab state
    switch (tab) {
      case "Dashboard":
        history.push("/iot-home");
        break;
      case "Reports":
        history.push("/iot-home/reports");
        break;
      case "Alarms":
        history.push("/iot-home/alarms");
        break;
      case "Reports Failure":
        history.push("/iot-home/reports-failure");
        break;
      case "ABT Meter":
        history.push("/iot-home/abt-meter");
        break;
      case "Plant Layout":
        history.push("/iot-home/plant-layout", { newInvArray });
        break;
      case "3D":
        history.push("/iot-home/three-d-twin");
        break;
      case "Monitoring":
        setOpenDrawer(!openDrawer);
        break;
      default:
        break;
    }
  };

  const handleMonitorDevices = () => {
    history.push("/iot-home/dev-monitoring")
  }
  const handleMenuClick = (deviceName) => {
    const routes = {
      Inverter: '/iot-home/dev-monitoring/inv-dashboard',
      MFM: '/iot-home/dev-monitoring/mfm-data',
      SMB: '/iot-home/dev-monitoring/smb',
      Trackers: '/iot-home/dev-monitoring/trackers',
      'VCB Panel': '/iot-home/dev-monitoring/vcb-panel',
      'HT Panel': '/iot-home/dev-monitoring/ht-panel',
      'Weather Dashboard': '/iot-home/dev-monitoring/weather-dashboard',
    };
   
    const stateData = {
      mfmDeviceData: tableData[0],
      mfmList: tableData,
      firstField: deviceFieldValue[0],

    };
    const stateDataTwo = {
      firstField: deviceFieldValueWMS[0],
    };
   
  
    if (routes[deviceName]) {
      if (deviceName === 'MFM') {
        history.push({
          pathname: routes[deviceName],
          state: stateData
        });
      }
      else if (deviceName === 'Weather Dashboard') {
        history.push({
          pathname: routes[deviceName],
          state: stateDataTwo
        });
      }
       else {
        history.push(routes[deviceName]);
      }
      setOpenDrawer(false);
    }
    setSelectedTab(deviceName);
  };

  const handleFieldsList = async () => {
    try {
      setLoading(true);
      const res = await invService.getListDeviceFields("mfm");
      if (res.status === 200) {
        setDeviceFieldValue(res.data);
      }
    } catch (err) {
      setLoading(false);
      handleFetchError(err, "Inverter field List");
    }
  };
  const handleFieldsListWMS = async () => {
    try {
      setLoading(true);
   const resTwo = await invService.getListDeviceFieldsWMS("wms")
      if (resTwo.status === 200) {
        setDeviceFieldValueWMS(resTwo.data);
      }
    } catch (err) {
      setLoading(false);
      handleFetchError(err, "Inverter field List");
    }
  };

  const handleMfmParameters = async (id) => {
    try {
      setLoading(true);
      const res = await invService.getDeviceLevelData(id, "mfm");
      if (res.status === 200) {
        const tableDatas = res.data.map((item) => ({
          name: item?.device_details?.name,
          current:item?.device_data?.phase_A_RMS_current_A.toFixed(2),
          voltage: item?.device_data?.phase_A_RMS_voltage_V.toFixed(2),
          Energy: item?.device_data?.positive_active_energy_kWh.toFixed(2),
          status: item?.device_details?.status === true ? "ON" : "OFF",
          raised_at: (
            item?.device_details?.last_active.match(
              /^(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2})/
            ) || []
          )
            .slice(1)
            .join(" "),
          device_data: item?.device_data?.device_id,
          id: item?.device_details?.id,
        }));
        setTableData(tableDatas);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      handleFetchError(err, "mfm field List");
    }
  };
  
  const mainListItems = (
    <React.Fragment>
      {sidebarTabs.map((item, index) => {
        const isActive = activeTab === item.name;
        const iconColor = (isActive && !isOpenSidebar) ? "#ffff" : isActive ? "#2F67F8" : "#111111";
        return (
          <ListItemButton key={index}
            focusVisibleClassName="sidebar-selected-iot"
            selected={isActive}
            autoFocus={true}
            onClick={() => handleMenuItemClick(item.name)}
            sx={{
              justifyContent: isOpenSidebar ? "flex-start !important" : "center !important", width: "100%",
              backgroundColor: "#F9F9FF",
            }}
          >
            <Tooltip title={item.name} arrow>
              <ListItemIcon className="center_center_flex">
                <div className="listItem_icon"
                  style={{ background: (isActive && !isOpenSidebar) ? "#2F67F8" : "transparent", padding: !isOpenSidebar ? "0.4rem" : "0vh" }}>
                  {item.icon ? (
                    <item.icon
                      width="1.7rem"
                      height="1.7rem"
                      strokeColor={iconColor}
                    />
                  ) : (
                    <div>--</div>
                  )}
                  {isOpenSidebar && <p className="Poppins_16px_rem"
                    style={{
                      color: isActive ? "#2F67F8" : "#111111",
                      fontWeight: isActive ? "600" : "400"
                    }}>{item.name}</p>}
                </div>

              </ListItemIcon>
            </Tooltip>
          </ListItemButton>
        )
      })}
      
    </React.Fragment>
  );

  return (
    <div>
      <div>
        {openDrawer ? <div className="sidebar-main-drawer"
          style={{
            marginLeft: isOpenSidebar ? "0rem" : "1rem",
          }}
        >
          <img src="https://s3.amazonaws.com/smarttrak.co/v2Images/Logo.svg" alt="logo" style={{ width: "10rem" }} />

        </div> : <div className="logo-container"
        >
          <img src="https://s3.amazonaws.com/smarttrak.co/v2Images/smarttrak_logo.svg" className="sidebar_logo"
            alt="smarttrakLogo" />

        </div>}
      </div>
      <div>
        <Drawer
          sx={{
            "& .MuiDrawer-paper": {
              width: isOpenSidebar ? "13rem" : "5rem",
              height: { height },
              transition: "transform 0.3s",
              transform: "translateY(0)",
              overflowY: "inherit ! important",
              backgroundColor: "#F9F9FF",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            },
            backgroundColor: "#F9F9FF"
          }}
          variant="permanent"
          open={open}
          className="custom-drawer-iot"
        >
          <List component="nav" className="nav_list" sx={{ width: isOpenSidebar ? "13rem" : "5rem"}}>
            {mainListItems}
          </List>
          {openDrawer && (
        <Box ref={drawerRef} sx={monitoringBoxStyle} component={Paper}>
          <div onClick={handleMonitorDevices} style={{ cursor: "pointer" }}>
            <p className="Poppins_18px_rem">Monitoring Devices</p>
          </div>
          <div className="monitoring-div">
            {devicesList.map((device, index) => {
              return <div onClick={() => handleMenuClick(device.name)}
              className="monitoring-menu"
                style={{
                   backgroundColor: selectedTab === device.name ? "#BBD6FF" : "transparent",
                }} key={index}>
                   {device.icon ? (<device.icon width="1.7rem" 
                   fillColor={device.fillColor}
                   fillColorPath2={device.fillColorPath2} strokeColorPath2={device.strokeColorPath2}
                      height="1.7rem" path1={device.path1} path2={device.path2} />) : (<div>--</div>)}
                  
                {/* <img src={device.icon} alt="device" style={{ width: "1.7rem" }} /> */}
                <p className="Poppins_16px_rem" style={{ fontWeight: "400" }}>{device.name}</p>
              </div>
            })}
          </div>
        </Box>
      )}
          <div onClick={handleArrowClick} className="toggle-arrow-div">
            {isOpenSidebar ? <KeyboardDoubleArrowLeftIcon className="left-right-arrow" />
              : <KeyboardDoubleArrowRightIcon className="left-right-arrow" />
            }
          </div>

        </Drawer>
      </div>

    </div>
  );
};

export default SideBar;