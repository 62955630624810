import React, { useState } from "react";

const CustomCircularBar = ({ centerText, values, unit, nameData }) => {
  const [hoverIndex, setHoverIndex] = useState(null);

  const generateColor = (index) => {
    const hue = (index * 37) % 360;
    return `hsl(${hue}, 70%, 50%)`;
  };

  const radius = 50;
  const circumference = 2 * Math.PI * radius;
  const totalValue = values.reduce((acc, val) => acc + val, 0);

  let cumulativeValue = 0;

  return (
    <div className="circular_main">
      <div className="circular_submain">
        <svg width="200" height="200" viewBox="0 0 120 120">
          <circle
            cx="60"
            cy="60"
            r={radius}
            stroke="#d6d6d6"
            strokeWidth="20"
            fill="none"
          />
          {values.map((value, index) => {
            if (value === 0) return null;

            const normalizedValue = (value / totalValue) * 100;
            const segmentLength = (normalizedValue / 100) * circumference;
            const strokeDasharray = `${segmentLength} ${
              circumference - segmentLength
            }`;
            const strokeDashoffset =
              circumference - (cumulativeValue / totalValue) * circumference;
            cumulativeValue += value;

            const color = generateColor(index);
            return (
              <g
                key={index}
                onMouseEnter={() => setHoverIndex(index)}
                onMouseLeave={() => setHoverIndex(null)}
              >
                <circle
                  cx="60"
                  cy="60"
                  r={radius}
                  stroke={color}
                  strokeWidth="20"
                  fill="none"
                  strokeDasharray={strokeDasharray}
                  strokeDashoffset={strokeDashoffset}
                  transform="rotate(-90 60 60)"
                />
              </g>
            );
          })}

          <text
            x="50%"
            y="35%"
            textAnchor="middle"
            dy=".3em"
            fontSize="20px"
            fill="#000"
          >
            <tspan x="50%" dy="1.2em" className="Poppins_12px_rem">
              Power
            </tspan>

            <tspan
              x="50%"
              dy="1.2em"
              className="Poppins_16px_rem"
            >{`${centerText}${unit}`}</tspan>
          </text>
        </svg>
      </div>
      <div className="legend_power">
        {nameData.map((name, index) => (
          <div key={index} className="center_div_nogap">
            <div
              className="circle_power"
              style={{ backgroundColor: generateColor(index) }}
            ></div>
            <div className="smb_value_container">
              <p className="Poppins_16px_400" style={{color:"#00000080"}}>{name}</p>
              <p className="Poppins_16px_400">
                {values[index]}
                {unit}
              </p>
            </div>
          </div>
        ))}
      </div>
      {hoverIndex !== null && (
        <div className="powerTooltip">
          <p className="Poppins_16px_400">
            {nameData[hoverIndex]}:
            <span style={{ fontWeight: "600" }}>
              {" "}
              {values[hoverIndex]}
              {unit}
            </span>
          </p>
        </div>
      )}
    </div>
  );
};

export default CustomCircularBar;
