import React, { useState } from 'react'
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useHistory } from "react-router-dom";
import SimpleBackdrop from '../../LifeScience/components/common/SimpleBackdrop';

function DeviceBeforeNext(props) {
    const {deviceList, dashboardName, deviceId, isDashboard} = props;
    const [currentPage, setCurrentPage] = useState(0); 
    const [loadingState, setLoadingState] = useState({
        trackerList: false
      });
    const itemsPerPage = 5; 
    const history = useHistory();
  const totalPages = Math.ceil(deviceList.length / itemsPerPage);

    const handleDeviceClick = (device, id, index) => {
        if(dashboardName === "Trackers Dashboard"){
      props.handleDevice(device, id)
    } else if (dashboardName === "Inverters Dashboard"){
        props.handleDevice(device)
    }
      }
    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
      };
    
      const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
      };
    
      const startIndex = currentPage * itemsPerPage;
    
     
  return (
    <Grid
    container
    className="inv_names_container"
    sx={{
      padding: "1rem !important", backgroundColor: "white !important",
      border: "0.0625rem solid #D8D8D8 !important", borderRadius: "0.75rem !important"
    }}
  >
    <Grid
      item
      lg={12}
      xs={12}
      sm={12}
      md={12}
      className="only_flex-start"
      sx={{position:"relative"}}
    >
      <Grid
        container
        className="inv-names_grid"
      >
        {deviceList.length > 0 ? (
<>
    <Grid item xs={12} className="a-center_j-flex-start">
      <div onClick={handlePrevPage} className="arrow-btn" 
      style={{backgroundColor:currentPage === 0 ? "#ebe7e7" : "#2F67F8",
        border:"1px solid #D8D8D8", cursor:currentPage === 0 ? "not-allowed" : "pointer",
        pointerEvents:currentPage === 0 ? "none" : "auto"
      }}>
        <ChevronLeftIcon sx={{color:currentPage === 0 ? "#b5afaf" : "white"}} />
      </div>
    </Grid>
    {deviceList.slice(startIndex, startIndex + itemsPerPage).map((ele, index) => (
      <Grid
        item
        key={index}
        lg={2.2}
        xs={12}
        sm={5.8}
        md={2.9}
        onClick={() => handleDeviceClick(ele, ele.device_name, index)}
        sx={{ flex: '0 0 auto', width: 'auto' }}
        className="flex-start_gap"
      >
        <Paper
          className="inv-names_paper"
          sx={{
            backgroundColor: isDashboard ?  index === 0 ? "#2F67F8" : "#ffff" : deviceId === ele.device_name ? "#2F67F8" : "#ffff",
            border: '0.0625rem solid #D8D8D8 !important',
          }}
        >
          <div
            className="inv-names_div"
            style={{
              justifyContent: 'center',
              backgroundColor:  isDashboard ?  index === 0 ? "#2F67F8" : "#ffff" : deviceId === ele.device_name ? "#2F67F8" : "#ffff",
            }}
          >
            <p
              className="Poppins_18px_rem"
              style={{
                color:  isDashboard ?  index === 0 ? "#ffff" : "#2F67F8" : deviceId === ele.device_name ? '#ffff' : 'rgb(47, 103, 248)',
              }}
            >
              {ele.device_name}
            </p>
          </div>
        </Paper>
      </Grid>
    ))}

    <Grid item xs={12} className="a-center_j-flex-end">
      <div onClick={handleNextPage} className="arrow-btn"
       style={{backgroundColor:currentPage === totalPages - 1 ? "#ebe7e7" : "#2F67F8",
        border:"1px solid #D8D8D8", cursor:currentPage === totalPages - 1 ? "not-allowed" : "pointer",
        pointerEvents:currentPage === totalPages - 1 ? "none" : "auto"
      }}>
        <ChevronRightIcon sx={{color:currentPage === totalPages - 1 ? "#b5afaf" : "white"}} />
      </div>
    </Grid>
  </>
        ) : (
          <div className='no-data'>
            <p className="Poppins_16px_rem" style={{ fontWeight: "400" }}>No data is available!</p>
          </div>
        )}
      </Grid>
      {loadingState.trackerList && (
        <SimpleBackdrop open={loadingState.trackerList} />
      )}
    </Grid>
  </Grid>
  )
}

export default DeviceBeforeNext