import React, { useState, useRef, useEffect } from "react";
import "./LandingPage.scss";
import { useHistory, useLocation } from "react-router-dom";

function Footer() {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [softwareSol, setSoftwareSol] = useState(false);
  const [hardwareSol, setHardwareSol] = useState(false);
  const softwareProds = [
    {name:"PowerOptix"},
    {name:"Forecasting"},
    {name:"Fault Prediction"},
    {name:"Digital Twin"},
    {name:"Remote Management"},
    {name:"Drone Integration"},
  ]
  const hardwareProds = [
    {name:"Tracker Controller"},
    {name:"SmartIQ Edge"},
  ]
  const anchorRef = useRef(null);
  const dropdownRef = useRef(null);
  const path = location.pathname;
  const handleProductClick = () => {
    setOpen((prevOpen) => !prevOpen);
  }
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setSoftwareSol(false);
        setHardwareSol(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const subMenuClick = (ele) => {
    if (ele === "Software") {
      setSoftwareSol(true);
      setHardwareSol(false);
    } else if (ele === "Hardware") {
      setHardwareSol(true);
      setSoftwareSol(false);
    }
  };
  const history = useHistory();
  const handleHomeClick = () => {
    history.push("/")
  }
  const handleCaseStudyClick = () => {
    history.push("/case-studies")
  }

 
  const handleServiceClick = () => {
    history.push("/our-services")
  }
  const handleAboutUs = () => {
    history.push("/about-us")
  }

  const handleCloseFirstSubMenu = ()=>{
    setSoftwareSol(false);
  }
  const handleCloseSecondSubMenu = ()=>{
    setHardwareSol(false);
  }

  const handleSoftProdsClick = (name) => {
    console.log("name", name);
    
    if(name === "PowerOptix"){
    history.push("/products/poweroptix")
     } else if (name === "Forecasting"){
      history.push("/products/energy_forecasting")
     } else if (name === "Fault Prediction"){
      history.push("/products/fault_prediction")
     } else if (name === "Digital Twin"){
      history.push("/products/three_d_twin")
     } else if (name === "Remote Management"){
      history.push("/products/remote_management")
     }  else if (name === "Drone Integration"){
      history.push("/products/drone_integration")
     } 
  };
  const handleHardProdsClick = (name) => {
    if(name === "Tracker Controller"){
    history.push("/products/tracker_controller")
     } else if (name === "SmartIQ Edge"){
      history.push("/products/smartiq_edge")
     }
  };

  return (
    <div className="footer-sec-container">
      <div className="footer-container">
        <div
          className="logo-text"
          style={{ display: "flex", flexDirection: "column", gap: "3vh" }}
        >
          <img className="footer-logo"
            src="https://s3.amazonaws.com/smarttrak.co/landing-images/smarttraklogo.svg"
            style={{ width: "13vw" }}
            alt="logo"
          />
          <p className="regular-common-text" style={{ color: "#D9D9D9" }}>
            SmartTrak: Empowering businesses with intelligent solutions and
            expert guidance in AI and technology to navigate the digital
            landscape and achieve remarkable success.
          </p>

          <div
            style={{ display: "flex", alignSelf: "flex-start", gap: "1.5vw" }}
          >
          
            <a>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="footer-social"
              viewBox="0 0 32 33"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 5.81543C0 3.05401 2.23858 0.81543 5 0.81543H27C29.7614 0.81543 32 3.05401 32 5.81543V27.8154C32 30.5769 29.7614 32.8154 27 32.8154H5C2.23858 32.8154 0 30.5769 0 27.8154V5.81543ZM22.1 12.3154C22.8 12.2154 23.4 12.1154 24 11.8154C23.6 12.5154 23 13.1154 22.3 13.5154C22.5 18.2154 19.1 23.3154 13 23.3154C11.2 23.3154 9.5 22.7154 8 21.8154C9.7 22.0154 11.5 21.5154 12.7 20.6154C11.2 20.6154 10 19.6154 9.6 18.3154C10.1 18.4154 10.6 18.3154 11.1 18.2154C9.6 17.8154 8.5 16.4154 8.5 14.9154C9 15.1154 9.5 15.3154 10 15.3154C8.6 14.3154 8.1 12.4154 9 10.9154C10.7 12.9154 13.1 14.2154 15.8 14.3154C15.3 12.3154 16.9 10.3154 19 10.3154C19.9 10.3154 20.8 10.7154 21.4 11.3154C22.2 11.1154 22.9 10.9154 23.5 10.5154C23.3 11.3154 22.8 11.9154 22.1 12.3154Z"
                fill="#4C4C4C"
              />
            </svg>
            </a>
            <a href="https://www.linkedin.com/company/smarttrak-ai/mycompany/" style={{cursor:"pointer"}}><svg
              xmlns="http://www.w3.org/2000/svg"
              className="footer-social"
              viewBox="0 0 32 33"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 5.81543C0 3.05401 2.23858 0.81543 5 0.81543H27C29.7614 0.81543 32 3.05401 32 5.81543V27.8154C32 30.5769 29.7614 32.8154 27 32.8154H5C2.23858 32.8154 0 30.5769 0 27.8154V5.81543ZM8.2 14.1154V24.8154H11.6V14.1154H8.2ZM8 10.7154C8 11.8154 8.8 12.6154 9.9 12.6154C11 12.6154 11.8 11.8154 11.8 10.7154C11.8 9.61543 11 8.81543 9.9 8.81543C8.9 8.81543 8 9.61543 8 10.7154ZM20.6 24.8154H23.8V18.2154C23.8 14.9154 21.8 13.8154 19.9 13.8154C18.2 13.8154 17 14.9154 16.7 15.6154V14.1154H13.5V24.8154H16.9V19.1154C16.9 17.6154 17.9 16.8154 18.9 16.8154C19.9 16.8154 20.6 17.3154 20.6 19.0154V24.8154Z"
                fill="#4C4C4C"
              />
            </svg>
            </a>
          
          </div>
        </div>
        <div className="nav-stuff" style={{ display: "flex", flexDirection: "column", gap: "3vh", paddingLeft: "6vw" }}>
          <p onClick={handleHomeClick}
            className="regular-common-text"
            style={{ color: path === "/" ? "#D9D9D9" : "#4C4C4C", fontWeight: "500", cursor: path === "/" ? "none" : "pointer" }}
          >
            Home
          </p>
          <p onClick={handleCaseStudyClick}
            className="regular-common-text"
            style={{ color: path === "/case-studies" || /\/case-study\/[0-5]/.test(path) ? "#D9D9D9" : "#4C4C4C", fontWeight: "500", cursor: "pointer" }}
          >
            Case Studies
          </p>
          <div className="footer_product">
            <p ref={anchorRef} onClick={handleProductClick}
            className="regular-common-text"
            style={{ color: path === "/products/poweroptix" || path === "/products/smartiq_edge" ||
              path === "/products/three_d_twin" ||
              path === "/products/energy_forecasting" ||
              path === "/products/drone_integration" ||
              path === "/products/fault_prediction" ||
              path === "/products/remote_management" ||
              path === "/products/tracker_controller"
              ? "#D9D9D9" : "#4C4C4C", fontWeight: "500", cursor: "pointer" }}
          >
            Products
          </p>
          {open && (
                <div
                  className="footer_dropdown-pro"
                >
                  <div
                    className="footer-pro"
                    onMouseEnter={() => subMenuClick("Software")} 
                    onMouseLeave={handleCloseFirstSubMenu}
                  >
                    <p className="pro-elements"
                    >Software</p>
                  </div>
                  <div
                    className="footer-pro"
                    onMouseEnter={() => subMenuClick("Hardware")} 
                    onMouseLeave={handleCloseSecondSubMenu}
                  >
                    <p className="pro-elements">Hardware</p>
                  </div>
                </div>
              )}
              { softwareSol && 
             <div ref={dropdownRef} className="footer_software_prods_container" onMouseEnter={()=>setSoftwareSol(true)} >
             {softwareProds.map((item, index)=>{
             return (
               <div key={index}
               className="footer_soft_prods_div"
               onClick={() => handleSoftProdsClick(item.name)} 
             >
               <p className="Poppins_16px_rem" style={{fontWeight:"400"}}
               >{item.name}</p>
             </div>
             )
             })}
             </div>
              }
 { hardwareSol && <div ref={dropdownRef} className="footer_software_prods_container" style={{top:"10vh"}} onMouseEnter={()=>setHardwareSol(true)} >
             {hardwareProds.map((item, index)=>{
             return (
               <div key={index}
               className="footer_soft_prods_div"
               onClick={() => handleHardProdsClick(item.name)} 
             >
               <p className="Poppins_16px_rem" style={{fontWeight:"400"}}
               >{item.name}</p>
             </div>
             )
             })}
             </div>}
                </div>
          <p onClick={handleServiceClick}
            className="regular-common-text"
            style={{ color: path === "/our-services" || /\/our-services\/[0-2]/.test(path) ? "#D9D9D9" : "#4C4C4C", fontWeight: "500", cursor: "pointer" }}
          >
            Solutions
          </p>
          <p onClick={handleAboutUs}
            className="regular-common-text"
            style={{ color: path === "/about-us" ? "#D9D9D9" : "#4C4C4C", fontWeight: "500", cursor: "pointer" }}
          >
            About us
          </p>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "3vh" }}>
          <p
            className="regular-common-text"
            style={{ color: "#D9D9D9", fontWeight: "500" }}
          >
            Privacy Policy
          </p>
          <p
            className="regular-common-text"
            style={{ color: "#D9D9D9", fontWeight: "500" }}
          >
            Terms & Conditions
          </p>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "3vh" }}>
          <p className="semibold-commontext" style={{ color: "#D9D9D9" }}>
            Contact Us
          </p>
          <div className="contact-us-footer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="footer-svg"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M18.8721 16.255L16.7321 18.395C14.8231 20.304 8.70282 17.7945 5.36485 14.4565C2.02657 11.1183 -0.48291 4.99856 1.42639 3.08926L3.56636 0.949289C4.20537 0.310281 5.21727 0.363843 5.78982 1.06366L8.6282 4.53287C9.17 5.1951 9.12174 6.19795 8.51746 6.80224L7.16544 8.15425C6.99485 8.32484 6.94819 8.67852 7.06816 8.87857C7.10487 8.94834 7.13245 9.00063 7.17747 9.07952C7.25617 9.21744 7.3536 9.37474 7.47032 9.54778C7.80778 10.0481 8.22998 10.5684 8.74151 11.0799C9.25301 11.5914 9.77391 12.014 10.2753 12.3521C10.4487 12.4691 10.6064 12.5667 10.7448 12.6457C10.8239 12.6909 10.8765 12.7186 10.8986 12.7295C11.1442 12.8705 11.4987 12.8243 11.6671 12.6559L13.0191 11.3039C13.6232 10.6998 14.6268 10.6518 15.2885 11.1932L18.7577 14.0316C19.4582 14.6047 19.5117 15.6154 18.8721 16.255ZM6.26511 7.25391L7.61712 5.90191C7.75818 5.76085 7.77092 5.49583 7.64272 5.33914L4.80437 1.86992C4.70769 1.75175 4.57177 1.74456 4.4667 1.84963L2.32673 3.9896C1.12919 5.18714 3.37651 10.6675 6.26518 13.5562C9.15357 16.4446 14.6345 18.6919 15.8318 17.4947L17.9718 15.3547C18.0774 15.249 18.0703 15.1143 17.9515 15.017L14.4822 12.1787C14.326 12.0508 14.0602 12.0635 13.9195 12.2043L12.5675 13.5563C11.9962 14.1275 11.0227 14.2592 10.3194 13.8635C10.1562 13.7819 9.89547 13.6317 9.56329 13.4077C8.99686 13.0257 8.41288 12.5519 7.84117 11.9802C7.26948 11.4085 6.79612 10.8252 6.41474 10.2598C6.19269 9.93062 6.04344 9.67186 5.96171 9.50877C5.55655 8.80547 5.69133 7.82768 6.26511 7.25391Z"
                fill="#D9D9D9"
              />
            </svg>

            <p className="regular-common-text" style={{ color: "#D9D9D9" }}>
              Phone:{" "}
              <span style={{ fontFamily: "Roboto" }}>(+1) 510-945-8169 </span>
            </p>
          </div>
          <div className="contact-us-footer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="footer-svg"
              viewBox="0 0 20 15"
              fill="none"
            >
              <path
                d="M19.1519 0.567341C19.1456 0.560974 19.1392 0.557791 19.1328 0.551425C18.9132 0.366801 18.6299 0.249023 18.3179 0.249023H1.76546C1.45351 0.249023 1.1702 0.366801 0.947381 0.554608C0.944198 0.557791 0.937832 0.560974 0.931465 0.567341C0.664079 0.799712 0.492188 1.14031 0.492188 1.52229V13.6183C0.492188 14.3218 1.06198 14.8916 1.76546 14.8916H18.3179C19.0214 14.8916 19.5912 14.3218 19.5912 13.6183V1.52229C19.5912 1.14031 19.4193 0.802895 19.1519 0.567341ZM17.2325 1.52229L10.0417 7.6881L2.85092 1.52229H17.2325ZM1.76546 13.6183V2.27034L9.62789 9.00911C9.74567 9.11097 9.89528 9.1619 10.0417 9.1619C10.1881 9.1619 10.3377 9.11097 10.4555 9.00911L18.3179 2.27034V13.6183H1.76546Z"
                fill="#D9D9D9"
              />
            </svg>
            <p className="regular-common-text" style={{ color: "#D9D9D9" }}>
              Email: info@smarttrak.co
            </p>
          </div>
          <div className="contact-us-footer">
            <div>
              <svg
                className="footer-svg"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 17 22"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.04211 4.69367C11.4406 4.69367 13.1536 8.82197 10.7483 11.2272C8.34353 13.6323 4.21476 11.9198 4.21476 8.52102C4.21476 6.40733 5.92842 4.69367 8.04211 4.69367ZM7.63506 21.4003C7.84444 21.6495 8.23041 21.653 8.444 21.4062C11.3615 18.032 16.0371 13.1999 16.0371 8.52102C16.0371 6.31318 15.142 4.31449 13.6953 2.86759C8.67118 -2.15701 0.046875 1.42091 0.046875 8.52079C0.046875 13.1981 4.71806 18.0266 7.63506 21.4003ZM7.5737 19.715C7.72781 19.8897 7.877 20.059 8.04211 20.2478C10.7687 17.131 14.9708 12.5969 14.9708 8.52079C14.9708 2.36803 7.49688 -0.732589 3.14281 3.62149C1.88888 4.87541 1.11344 6.60734 1.11344 8.52079C1.11344 12.3891 4.94805 16.7376 7.5737 19.715ZM9.99419 6.5687C8.25922 4.83396 5.28132 6.06914 5.28132 8.52079C5.28132 10.9729 8.25968 12.2074 9.99419 10.4729C11.0722 9.39483 11.0722 7.64697 9.99419 6.5687Z"
                  fill="#D9D9D9"
                />
              </svg></div>
            <div style={{ display: "flex", flexDirection: "column", gap:"1vw" }}> 
            <div style={{display:"flex", gap:"1vw", alignItems:"flex-start"}}><img src="https://s3.amazonaws.com/smarttrak.co/landing-images/usa.png" alt="usa" className="footer-social" />
              <p className="regular-common-text" style={{ color: "#D9D9D9" }}>
                <span style={{ fontFamily: "Roboto" }}>#107, 2</span>  Davis Drive Research Triangle Park,
                 NC <span style={{ fontFamily: "Roboto" }}>27709</span>
              </p>
              </div>

             
                <div style={{display:"flex", gap:"1vw", alignItems:"flex-start"}}><img src="https://s3.amazonaws.com/smarttrak.co/landing-images/india.png" alt="india" className="footer-social" />
                <p className="regular-common-text" style={{ color: "#D9D9D9" }}>
                  <span style={{ fontFamily: "Raleway" }}>T-Hub, Hyderabad Knowledge City, Serilingampally, Hyderabad, Telangana <span style={{ fontFamily: "Roboto" }}>500081</span></span>
                </p>
                </div>
             
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ textAlign: "center", paddingTop: "8vh" }}
      >
        <p className="regular-common-text">
          {" "}
          &copy; All rights Reserved to @ smarttrak.co
        </p>
      </div>
    </div>
  );
}

export default Footer;
