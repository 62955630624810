import React, { useState, useEffect, useCallback } from 'react';
import ReactFlow, { useNodesState, useEdgesState, addEdge, MiniMap, Controls } from 'reactflow';
import 'reactflow/dist/style.css';
import { NavLink } from 'react-router-dom';
import { Tooltip as ReactTooltip } from "react-tooltip";
import "../PlantLayout/PlantLayout.scss";
import CommonText from '../../../IOT/Common/CommonText';

const initBgColor = 'white';

const connectionLineStyle = { stroke: 'black' };
const snapGrid = [20, 20];

const defaultViewport = { x: 0, y: 0, zoom: 1.5 };

const HtPanel = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [bgColor, setBgColor] = useState(initBgColor);

   const scbBoxes = [
  {
    name:"SCB F4",
    value:"20A"
  },
  {
    name:"SCB F5",
    value:"20A"
  },
  {
    name:"SCB F6",
    value:"20A"
  },
  {
    name:"SCB F7",
    value:"20A"
  },
  {
    name:"SCB G2",
    value:"20A"
  },
  {
    name:"SCB G3",
    value:"20A"
  },
  {
    name:"SCB G4",
    value:"20A"
  },
  {
    name:"SCB G5",
    value:"20A"
  },
  {
    name:"SCB H1",
    value:"20A"
  },
  {
    name:"SCB H2",
    value:"20A"
  },
  {
    name:"SCB E1",
    value:"20A"
  },
  {
    name:"SCB E2",
    value:"20A"
  },
  {
    name:"SCB E3",
    value:"20A"
  },
  {
    name:"SCB E4",
    value:"20A"
  },
  {
    name:"SCB E5",
    value:"20A"
  },
  {
    name:"SCB F1",
    value:"20A"
  },
  {
    name:"SCB F2",
    value:"20A"
  },
  {
    name:"SCB F3",
    value:"20A"
  },
  {
    name:"SCB F8",
    value:"20A"
  },
  {
    name:"SCB G1",
    value:"20A"
  },
 
 ]

  useEffect(() => {
    const onChange = (event) => {
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id !== '2') {
            return node;
          }

          const color = event.target.value;

          setBgColor(color);

          return {
            ...node,
            data: {
              ...node.data,
              color,
            },
          };
        })
      );
    };

    setNodes([
      {
        id: '1',
        type: 'selectorNode',
        data: { label: (
          <NavLink to="/iot-home/dev-monitoring/inverter/inv-detail">
            <img data-tooltip-id="my-tooltip-1" src="https://s3.amazonaws.com/smarttrak.co/v2Images/inverter_layoutimg.svg" alt="Inverter" style={{ width: '6vw' }} />
            <div><CommonText type="Regular_400" text="Inverter 04" /></div>
          </NavLink>
        )},
        position: { x: 120, y: -200 },
        sourcePosition: 'top',
        targetPosition:"bottom",
        style:{background: 'transparent', border:"1px solid transparent", display:"flex",flexDirection:"column", alignItems:"center", justifyContent:"center"}
      },
      {
        id: '2',
        type: 'selectorNode',
        data: { label:(
          <>
            <img src="https://s3.amazonaws.com/smarttrak.co/v2Images/inverter_layoutimg.svg" alt="Inverter" style={{ width: '6vw' }} />
            <div><CommonText type="Regular_400" text="Inverter 03" /></div>
          </>
        ) },
        style: { border: '1px solid #777', padding: 10 },
        position: { x: 1140, y: -180 },
        sourcePosition: 'top',
        targetPosition:"bottom",
        style:{background: 'transparent', border:"1px solid transparent", display:"flex",flexDirection:"column", alignItems:"center", justifyContent:"center"}
      },
     
    
      {
        id: '3',
        type: 'output',
        data: { label: (
          <>
            <img src="https://s3.amazonaws.com/smarttrak.co/v2Images/ht_panel.svg" alt="vcb-panel" style={{ width: '6vw' }} />
            <div><CommonText type="Regular_400" text="HT Panel" /></div>
          </>
        )},
        position: { x: 620, y: -480 },
        targetPosition: 'bottom',
        style:{background: 'transparent', border:"1px solid transparent", display:"flex",flexDirection:"column", alignItems:"center", justifyContent:"center"}
      },
     
    ]);

    setEdges([
      {
        id: 'e1-5',
        source: '1',
        target: '5',
        animated: true,
        style: { stroke: 'black' },
      },
      {
        id: 'e2a-5',
        source: '2',
        target: '5',
        sourceHandle: 'a',
        animated: true,
        style: { stroke: 'black' },
      },
      {
        id: 'e1a-3',
        source: '1',
        target: '3',
        sourceHandle: 'a',
        animated: true,
        style: { stroke: 'black' },
      },
      {
        id: 'e2a-3',
        source: '2',
        target: '3',
        sourceHandle: 'a',
        animated: true,
        style: { stroke: 'black' },
      },
     
    ]);
  }, []);
  useEffect(() => {
    // Creating a new node for the collection of the first 10 scbBoxes
    const scbNode1 = {
      id: 'scbNode1',
      type: 'selectorNode',
      data: {
        label: <div><CommonText type="Regular_400" text="SCBs" /></div>,
      },
      position: { x: 120, y: 160 }, // Adjust position as needed
      sourcePosition:"top",
      targetPosition:"bottom",
      style: { border: '1px solid #777', padding: 10 },
    };
    const scbNode2 = {
      id: 'scbNode2',
      type: 'selectorNode',
      data: {
        label: <div><CommonText type="Regular_400" text="SCBs" /></div>,
      },
      position: { x: 1140, y: 160 }, 
      sourcePosition:"top",
      targetPosition:"bottom",
      style: { border: '1px solid #777', padding: 10 },
    };
    
    // Adding the first 10 scbBoxes to the nodes state
    const scbBoxNodes1 = scbBoxes.slice(0, 10).map((box, index) => ({
      id: `scbBox1-${index}`,
      type: 'selectorNode',
      data: {
        label: (
          <div>
            {box.name}<br />
          </div>
        ),
      },
      position: { x: -380 + index * 110, y: 400 }, 
      sourcePosition:"top",
      style: { border: '1px solid #777', padding: 10, width:"100px" },
    }));
    const scbBoxNodes2 = scbBoxes.slice(10, 20).map((box, index) => ({
      id: `scbBox2-${index}`,
      type: 'selectorNode',
      data: {
        label: (
          <div>
            {box.name}<br /> 
          </div>
        ),
      },
      position: { x: 720 + index * 110, y: 400 },
      sourcePosition:"top",
      style: { border: '1px solid #777', padding: 10, width:"100px" },
    }));
   
    // Update nodes and edges state
    setNodes((prevNodes) => [...prevNodes, scbNode1,scbNode2, ...scbBoxNodes1, ...scbBoxNodes2]);
    setEdges((prevEdges) => [
      ...prevEdges,
      ...scbBoxNodes1.map((boxNode1) => ({
        id: `e-scbBox-${boxNode1.id}`,
        source: boxNode1.id,
        target: 'scbNode1',
        animated: true,
        style: { stroke: 'black' },
      })),
      {
        id: 'e-scbNode1-inverter1',
        source: 'scbNode1',
        target: '1', 
        animated: true,
        style: { stroke: 'black' },
      },
      ...scbBoxNodes2.map((boxNode2) => ({
        id: `e-scbBox-${boxNode2.id}`,
        source: boxNode2.id,
        target: 'scbNode2',
        animated: true,
        style: { stroke: 'black' },
      })),
      {
        id: 'e-scbNode2-inverter2',
        source: 'scbNode2',
        target: '2', 
        animated: true,
        style: { stroke: 'black' },
      },
    ]);
  }, []); 
  useEffect(() => {
    const gap = 90;
    const xOffset = -410;
    const panelNodes = Array.from({ length: 20 }, (_, index) => ({
      id: `panel-${index}`,
      type: 'input',
      data: {
        label: (
          <>
            <img src="https://s3.amazonaws.com/smarttrak.co/v2Images/solar_panel.svg" alt="panel" style={{ width: '4vw' }} />
          </>
        ),
      },
      position: { x: xOffset + index * (20 + gap), y: 457 }, 
      sourcePosition: 'top',
      style:{backgroundColor:"transparent", border:'1px solid transparent', display:"flex",flexDirection:"column", alignItems:"center", justifyContent:"center"}
    }));
  
    setNodes((prevNodes) => [...prevNodes, ...panelNodes]);
  }, []);
  
  const onConnect = useCallback(
    (params) =>
      setEdges((eds) => addEdge({ ...params, animated: true, style: { stroke: 'black' } }, eds)),
    []
  );
  
  return (
    <><ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      style={{ background: bgColor }}
      connectionLineStyle={connectionLineStyle}
      snapToGrid={true}
      snapGrid={snapGrid}
      defaultViewport={defaultViewport}
      fitView
      attributionPosition="bottom-left"
    >
      
      <MiniMap
        nodeStrokeColor={(n) => {
          if (n.type === 'input') return '#0041d0';
          if (n.type === 'selectorNode') return bgColor;
          if (n.type === 'output') return '#ff0072';
        }}
        nodeColor={(n) => {
          if (n.type === 'selectorNode') return bgColor;
          return 'black';
        }}
      />
      <Controls />
    </ReactFlow>
     <ReactTooltip
     id="my-tooltip-1"
     place="bottom"
     multiline={true} 
     variant="info"
   >
     <span>DC Current: 337A</span>
     <br />
     <span>DC Input Power: 223kW</span>
   </ReactTooltip>
   </>
  );
};

export default HtPanel;
